import { gql } from "@apollo/client";

export const COMPLETE_ACCOUNT = gql`
  mutation CompleteAccount($payload: inputCreateAccount!) {
    completeAccount(payload: $payload)
  }
`;

export const CREATE_ORG = gql`
  mutation CreateOrg($payload: inputCreateOrg!) {
    createOrg(payload: $payload)
  }
`;

export const CREATE_TEAM = gql`
  mutation CreateTeam($team: inputTeam!) {
    createTeam(team: $team) {
      _id
      name
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation CreateProject($project: inputProject!) {
    createProject(project: $project) {
      _id
      name
    }
  }
`;

export const INVITE_USERS = gql`
  mutation InviteSpaceUsers(
    $emailIds: [Email!]!
    $privilege: SpacePrivilegeInput!
  ) {
    inviteSpaceUsers(emailIds: $emailIds, privilege: $privilege)
  }
`;
