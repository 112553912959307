import { AuthWrapper } from "../../components";
import { useState, useCallback, useEffect } from "react";

import { useMutation } from "@apollo/client";

import { FORGOT_PASSWORD } from "./queries";

import { Text, toast, Input, Button } from "samespace-zen";

import { emailRegex } from "../../utils/common";

import { IconBack, IconEmailSuccess } from "../../utils/icons";
import { useHistory } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";

const ForgotPasswordForm = () => {
  const history = useHistory();
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);
  const [formPhase, setFormPhase] = useState(1);
  const [email, setEmail] = useState("");
  const onChangeHandler = useCallback((e) => {
    setEmail(e.target.value);
  }, []);
  const disabledButton = useCallback(() => {
    if (email.match(emailRegex)) {
      return false;
    } else return true;
  }, [email]);
  const [btnStates, setBtnStates] = useState({
    success: false,
    loading: false,
    failed: false,
  });
  const submitFormHandler = useCallback(
    (resend) => {
      setBtnStates({
        ...btnStates,
        loading: true,
      });
      forgotPassword({
        variables: {
          email: email,
        },
      })
        .then((res) => {
          if (!resend) {
            setBtnStates({
              ...btnStates,
              loading: false,
              success: true,
            });
            setTimeout(() => {
              setFormPhase(2);
            }, 1300);
          } else {
            toast.success("Link Sent Successfully");
          }
        })
        .catch((e) => {
          toast.error(e?.message || "Something Went Wrong");
          setBtnStates({
            ...btnStates,
            failed: true,
            loading: false,
          });
          setTimeout(() => {
            setBtnStates({
              ...btnStates,
              failed: false,
            });
          }, 2000);
        });
    },
    [email, forgotPassword, btnStates]
  );

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.keyCode === 13) {
        submitFormHandler();
      }
    };

    document.addEventListener("keyup", handleKeyPress);
    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, [submitFormHandler]);
  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: { duration: 0.4, ease: "easeIn" },
        }}
        exit={{
          opacity: 0,
          transition: { duration: 0.4, ease: "easeIn" },
        }}
      >
        <AnimatePresence exitBeforeEnter>
          {formPhase === 1 && (
            <motion.div
              key="first-phase"
              exit={{
                x: -100,
                opacity: 0,
                transition: { duration: 0.4, ease: "easeIn" },
              }}
            >
              <div className="flex flex-col items-center justify-center">
                <div className=" w-[400px] flex items-center justify-start">
                  <Text
                    label="Reset your password"
                    size="xl"
                    weight="semibold"
                    className="mb-2"
                  />
                </div>
                <div className=" w-[400px] flex items-center justify-start text-left">
                  <Text
                    label="Enter the email address associated with your account and we'll send you a link to reset your password."
                    size="sm"
                    color="muted"
                    className="mb-6"
                  />
                </div>

                <div className="w-[400px] text-left flex flex-col items-center">
                  <Input
                    autoFocus
                    value={email}
                    placeholder="Email"
                    name="email"
                    type="email"
                    onChange={onChangeHandler}
                  />

                  <Button
                    full
                    disabled={disabledButton()}
                    loading={btnStates.loading}
                    success={btnStates.success}
                    failure={btnStates.failed}
                    label="Send Link"
                    className="mt-[40px]"
                    shape="rounded"
                    onClick={submitFormHandler.bind(null, false)}
                  />
                  <div
                    onClick={() => {
                      history.push("/login");
                    }}
                    className="w-full p-2 hover:bg-gray-200 mt-4 cursor-pointer rounded-full flex items-center justify-center"
                  >
                    <Text
                      size="sm"
                      icon={
                        <div className="-rotate-180 flex items-center justify-center">
                          {IconBack}
                        </div>
                      }
                      className="text-sm opacity-50"
                      label="Back to login"
                    />
                  </div>
                </div>
              </div>
            </motion.div>
          )}
          {formPhase === 2 && (
            <motion.div
              key="second-phase"
              intial={{
                x: 100,
                opacity: 0,
                transition: { duration: 0.4, ease: "easeIn" },
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: { duration: 0.4, ease: "easeIn" },
              }}
              className="flex flex-col items-center justify-center"
            >
              {IconEmailSuccess}
              <Text
                label="Email Sent"
                size="2xl"
                weight="bold"
                className="mb-2 mt-4"
              />

              <Text
                label="Thanks, check your email for instructions to reset your password"
                color="muted"
                size="sm"
                className="w-[320px]"
              />
              <div className="flex mt-4">
                <Text
                  className="hidden sm:inline-flex"
                  label="Didn’t see an email?"
                  color="muted"
                  size="sm"
                />
                &nbsp;
                <Text
                  onClick={submitFormHandler.bind(null, true)}
                  size="sm"
                  color="primary"
                  label="Resend"
                  className="hover:underline cursor-pointer"
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </>
  );
};

const ForgotPassword = () => {
  return <AuthWrapper body={<ForgotPasswordForm />} />;
};

export default ForgotPassword;
