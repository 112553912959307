import { gql } from "@apollo/client";

export const GET_USER_PROFILE = gql`
  query GetUser {
    getUser {
      id
      firstName
      lastName
      email
      resetPasswordDate
      location
      phoneNumber
      workProfile
      photoUrl
      appTheme
      dob
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export const SET_PASSWORD = gql`
  mutation SetPassword($newPassword: String!, $confirmPassword: String!) {
    setPassword(newPassword: $newPassword, confirmPassword: $confirmPassword)
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUser($payload: inputUpdateUser!) {
    updateUser(payload: $payload)
  }
`;

export const UPDATE_USER_EMAIL = gql`
  mutation UpdateUserEmail($email: Email!, $code: Int!) {
    updateUserEmail(email: $email, code: $code)
  }
`;

export const SEND_MAIL_FOR_VERIFICATION = gql`
  mutation SendMailForVerification($email: Email!, $category: Category!) {
    sendMailForVerification(email: $email, category: $category)
  }
`;

export const GET_CITY_COUNTRY_MAP = gql`
  query getCityCountryMap($query: String!, $limit: Int, $offset: Int) {
    getCityCountryMap(query: $query, limit: $limit, offset: $offset)
  }
`;

export const LOGOUT_SESSION = gql`
  mutation LogoutSession($id: String!) {
    logoutSession(id: $id)
  }
`;
