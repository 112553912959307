import { motion } from "framer-motion";

const SideAnimationPage = ({ children }) => {
  return (
    <motion.div
      initial={{
        y: 100,
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        y: 0,
        transition: { duration: 0.4, ease: "easeOut" },
      }}
      exit={{
        y: -100,
        opacity: 0,
        transition: { duration: 0.4, ease: "easeIn" },
      }}
      className="w-[500px] h-full flex flex-col items-center justify-center gap-[40px]"
    >
      {children}
    </motion.div>
  );
};

export default SideAnimationPage;
