import { useCallback } from "react";
import { IconMacPC, IconWindowsPC } from "../icons";
import { Text, Button } from "samespace-zen";
import { useHistory } from "react-router-dom";

import { isWindows, isMacintosh } from "../../../utils/common";
const DownloadApp = () => {
  const installSuccessHandler = useCallback((type) => {
    if (type === "windows") {
      window.location = `https://storage.googleapis.com/samespace_apps/windows/prod/WorkspaceInstaller.exe`;
    } else {
      window.location = `https://storage.googleapis.com/samespace_apps/macos/prod/Workspace.dmg`;
    }
    window.location.href = "/work";
  }, []);

  const history = useHistory();
  return (
    <>
      <Text
        label="One more thing"
        weight="semibold"
        className="!text-[32px] text-[#333333]"
      />
      {isWindows() && (
        <div className="flex flex-col items-center">
          {IconWindowsPC}
          <Text label="Windows" className="mt-[20px]" />
          <Text className="opacity-60" label="Windows 10 or higher" />
        </div>
      )}
      {isMacintosh() && (
        <div className="flex flex-col items-center">
          {IconMacPC}
          <Text label="MacOS" className="mt-[20px]" />
          <Text className="opacity-60" label="OS X 10.10+" />
        </div>
      )}
      <div className="flex flex-col gap-[12px] w-full">
        <Button
          size="medium"
          onClick={installSuccessHandler.bind(
            null,
            isWindows() ? "windows" : "macOS"
          )}
          label="Download App"
          full
        />
        <div className="onboarding-secondary-btn">
          <Button
            size="medium"
            accent="contrast"
            full
            appearance="ghost"
            label="I will do it later"
            onClick={() => {
              history.push("/work");
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DownloadApp;
