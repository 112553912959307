import { useMutation } from "@apollo/client";

import { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Text, Select, Button } from "samespace-zen";

import { getCookie } from "../../../utils/cookies";
import { INVITE_USERS } from "./queries";

const InvitePeople = () => {
  const history = useHistory();
  const [btnStates, setBtnStates] = useState({
    failure: false,
    success: false,
    pending: false,
  });
  const [createInvite] = useMutation(INVITE_USERS, {
    context: {
      headers: {
        Authorization: getCookie("spaceToken"),
      },
    },
  });

  const [email, setEmail] = useState([]);

  const formSubmitHandler = useCallback(() => {
    setBtnStates((b) => {
      return {
        ...b,
        pending: true,
      };
    });
    createInvite({
      variables: {
        emailIds: email.map((item) => item.label),
        privilege: {
          workspacePrivilegeInput: {
            user: 1,
            admin: 0,
            team_dashboard: 0,
            dashboard: 0,
          },
        },
      },
    })
      .then((res) => {
        setBtnStates((b) => {
          return {
            ...b,
            pending: false,
            success: true,
          };
        });
        setTimeout(() => {
          history.push("/work/setup/download");
        }, 1300);
      })
      .catch((e) => {
        setBtnStates((b) => {
          return {
            ...b,
            pending: false,
            failure: true,
          };
        });
        setTimeout(() => {
          setBtnStates((b) => {
            return {
              ...b,
              pending: false,
              failure: true,
            };
          });
        }, 2000);
      });
  }, [createInvite, email, history]);

  return (
    <>
      <Text
        label="Invite team members"
        weight="bold"
        className="!text-[32px] text-[#333333]"
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formSubmitHandler();
        }}
        className="w-full flex flex-col gap-y-8"
      >
        <div className="w-full setup-invite-page">
          <Text label="Send Invites" size="sm" className="opacity-60" />
          <Select
            onAdd={(e) => {
              setEmail([...email, e.target]);
            }}
            tagsStyle
            options={[]}
          />
          <Text
            size="sm"
            className="opacity-60"
            label="Type or paste emails separated by commas, spaces"
          />
        </div>
        {/* {!["gmail.com", "hotmail.com", "yahoo.com", "ymail.com"].includes(
        "pankaj.patil@samespace.com".split("@")[1]
      ) && (
        <>
          <div className="invite-users-divider" />
          <div className="self-start">
            <Checkbox
              checked={allowSameDomain}
              onChange={(e) => setAllowSameDomain(e.target.checked)}
              label={
                <div>
                  Allow anyone with{" "}
                  <b>@{"pankaj.patil@samespace.com".split("@")[1]}</b> email to
                  join your organization.
                </div>
              }
            />
          </div>
        </>
      )} */}
        <div className="flex flex-col gap-[12px] w-full">
          <Button
            type="submit"
            size="medium"
            loading={btnStates.pending}
            success={btnStates.success}
            failure={btnStates.failure}
            onClick={formSubmitHandler}
            disabled={email.length === 0}
            label="Continue"
            full
          />
          <div className="onboarding-secondary-btn">
            <Button
              size="medium"
              accent="contrast"
              full
              appearance="ghost"
              label="I will do it later"
              onClick={() => {
                history.push("/work/setup/download");
              }}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default InvitePeople;
