import React from "react";
import ReactDOM from "react-dom";
import { App as AppWrapper } from "samespace-zen";
import { Router } from "react-router-dom";

import App from "./App";
import { ErrorBoundary } from "./components";
import { SessionProvider } from "./context/session";
import { ApolloProvider } from "@apollo/client";
import { authClient } from "./graphql/Provider";

const SamespaceApp = () => {
  return (
    <AppWrapper basename="/">
      {({ history }) => {
        return (
          <ErrorBoundary>
            <Router history={history}>
              <SessionProvider>
                <ApolloProvider client={authClient}>
                  <App history={history} />
                </ApolloProvider>
              </SessionProvider>
            </Router>
          </ErrorBoundary>
        );
      }}
    </AppWrapper>
  );
};

ReactDOM.render(<SamespaceApp />, document.getElementById("root"));
