import { Text, Input, Select, Button } from "samespace-zen";

import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getCookie, setCookie } from "../../../utils/cookies";
import { parseJwt } from "../../../utils/common";

import { COMPLETE_ACCOUNT } from "./queries";
import { useMutation } from "@apollo/client";
const Profile = () => {
  const history = useHistory();

  useEffect(() => {
    const userToken = getCookie("userToken");

    if (userToken) {
      const decodeToken = parseJwt(userToken);
      setPayload({
        firstName: decodeToken.firstName,
        lastName: decodeToken.lastName,
        workProfile: decodeToken.workProfile,
      });
    }
  }, []);
  const [payload, setPayload] = useState({
    firstName: "",
    lastName: "",
    workProfile: "",
  });
  const [btnStates, setBtnStates] = useState({
    failure: false,
    success: false,
    pending: false,
  });
  const onChangeHandler = useCallback((e) => {
    setPayload((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }, []);
  const disabledButtonHandler = useCallback(() => {
    if (!payload.firstName || !payload.lastName || !payload.workProfile) {
      return true;
    } else return false;
  }, [payload]);
  const [completeAccount] = useMutation(COMPLETE_ACCOUNT, {
    context: {
      headers: {
        authorization: getCookie("userToken"),
      },
    },
  });

  const submitFormHandler = useCallback(() => {
    setBtnStates((b) => {
      return {
        ...b,
        pending: true,
      };
    });
    completeAccount({
      variables: {
        payload: {
          ...payload,
          workProfile: payload.workProfile.value,
        },
      },
    })
      .then(({ data }) => {
        setCookie("userToken", data.completeAccount);
        setBtnStates((b) => {
          return {
            ...b,
            success: true,
            pending: false,
          };
        });
        setTimeout(() => {
          history.push("/work/setup/typeselect");
        }, 1300);
      })
      .catch((e) => {
        console.log(e.message, "ERROR");
        setBtnStates((b) => {
          return {
            ...b,
            failure: true,
            pending: false,
          };
        });
        setTimeout(() => {
          setBtnStates((b) => {
            return {
              ...b,
              failure: false,
              pending: false,
            };
          });
        }, 2000);
      });
  }, [completeAccount, history, payload]);
  return (
    <>
      <Text
        label="Tell us about yourself"
        weight="semibold"
        className="!text-[32px] text-[#333333]"
      />
      <form
        className="w-full flex flex-col gap-y-8"
        onSubmit={(e) => {
          e.preventDefault();
          submitFormHandler();
        }}
      >
        <Input
          size="medium"
          autoFocus
          label="First Name"
          name="firstName"
          value={payload.firstName}
          onChange={onChangeHandler}
        />
        <Input
          size="medium"
          label="Last Name"
          name="lastName"
          value={payload.lastName}
          onChange={onChangeHandler}
        />
        <Select
          size="medium"
          label="What do you do?"
          name="workProfile"
          value={payload.workProfile}
          onChange={onChangeHandler}
          options={[
            {
              label: "Software Engineer",
              value: "Software Engineer",
            },
            {
              label: "Support Engineer",
              value: "Support Engineer",
            },
            {
              value: "Accounts & Finance",
              label: "Accounts & Finance",
            },
            {
              value: "Marketing",
              label: "Marketing",
            },
          ]}
        />

        <Button
          size="medium"
          type="submit"
          loading={btnStates.pending}
          success={btnStates.success}
          failure={btnStates.failure}
          label="Continue"
          disabled={disabledButtonHandler()}
          full
        />
      </form>
    </>
  );
};

export default Profile;
