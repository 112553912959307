import React from "react";

const DotSteps = ({ step }) => {
  return (
    <div className="flex items-center h-[8px] my-[40px]">
      {[1, 2, 3, 4, 5].map((item) => {
        if (item <= step) {
          return (
            <div className="rounded-full h-[8px] w-[8px] dots-background-active mr-[8px]" />
          );
        }
        return (
          <div
            className={`rounded-full h-[8px] w-[8px] dots-background ${
              item === 5 ? "" : "mr-[8px]"
            }`}
          />
        );
      })}
    </div>
  );
};

export default DotSteps;
