import { avatarColors, products } from "./constants";
import { COOKIE_DOMAIN, getCookie, setCookie } from "./cookies";
export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function randomColorGenerator(index, nocolor) {
  if (nocolor) {
    return "0,0,0";
  }
  return avatarColors[index % avatarColors.length];
}

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export function getSavedSession() {
  const sessionId = getCookie("sessionId");
  const user = getCookie("user");
  const organizations = getCookie("organizations");
  const activeOrg = getCookie("activeOrg");
  const activeSpace = getCookie("activeSpace");
  const orgToken = getCookie("orgToken");
  const spaceToken = getCookie("spaceToken");
  if (sessionId && user) {
    return {
      sessionId,
      user,
      organizations,
      activeOrg,
      activeSpace,
      orgToken,
      spaceToken,
    };
  } else {
    return false;
  }
}

export function logout() {
  const newOrg = getCookie("newOrg") ? getCookie("newOrg") : [];
  document.cookie &&
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(
          /=.*/,
          "=;expires=Thu, 01 Jan 1970 00:00:01 GMT" +
            ";path=/" +
            `;domain=${COOKIE_DOMAIN}`
        );
    });
  if (newOrg.length > 0) {
    setCookie("newOrg", JSON.stringify(newOrg));
  }
  sessionStorage.removeItem("USER_NAMES_MAP");
  localStorage.removeItem("from");
  localStorage.removeItem("to");
  localStorage.removeItem("THEME");
  window.location.href = "/login";
}

export function getDomain() {
  return window.location.hostname.split(".").slice(1).join(".");
}

export function routeToActiveProductHome(history) {
  const savedSession = getSavedSession();
  const { activeSpace, organizations, activeOrg } = savedSession;
  const activeSpaceData =
    (organizations[activeOrg].spaces || []).find(
      (sp) => sp.spaceId === +activeSpace
    ) || {};
  const productId = activeSpaceData.productName;
  const product = products.find((pr) => pr.id === productId);
  if (product) {
    history.replace(`/${product.path}`);
    return true;
  } else {
    history.replace(`/work`);
    return false;
  }
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function getSpaceToken() {
  return getCookie("spaceToken");
}
export function getOrgToken() {
  return getCookie("orgToken");
}
export const getActiveSpaceId = () => {
  return getCookie("activeSpace");
};

export function getConnectBaseUrl() {
  const token = getSpaceToken();
  if (!token) {
    return undefined;
  }
  const data = parseJwt(token);
  const { instanceId } = data;
  if (instanceId) {
    return `https://${instanceId}.samespace.com`;
  }
  return undefined;
}
export function isAdmin() {
  const token = getOrgToken();
  if (!token) {
    return false;
  }
  const data = parseJwt(token);
  const { role } = data;
  if (["Admin", "Owner"].includes(role)) return true;
  else return false;
}

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null;
};

export function getAnalyticsLink() {
  let spaceToken = getSpaceToken();
  spaceToken = parseJwt(spaceToken);
  const privilege = spaceToken.privilege || {};
  const admin = privilege.admin;
  const manage = privilege.manage;
  const insights = privilege.insights;
  const agentInsights = !admin && !manage && !insights;
  return agentInsights ? "/connect/user-insights" : "/connect/analytics";
}

export const getPrivilege = () => {
  const spaceToken = getSpaceToken();
  if (spaceToken) {
    const privilege = parseJwt(spaceToken).privilege;
    return privilege;
  } else return {};
};

export const isMacintosh = () => {
  return navigator.platform.indexOf("Mac") > -1;
};

export const isWindows = () => {
  return navigator.platform.indexOf("Win") > -1;
};

export function getActiveOrgUrl() {
  const spaceToken = getCookie("spaceToken");
  const tokenData = parseJwt(spaceToken) || {};
  const { instanceId, services } = tokenData;
  const apiInfo = services.find((item) => item.name === "proxy");
  const activeOrgUrl = `https://${apiInfo.prefix}${instanceId}${apiInfo.suffix}.samespace.com/`;
  return activeOrgUrl;
}
