import { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { Text, Button } from "samespace-zen";
import { IconWorkerMale, IconTickPurple, IconUntick } from "../icons";

const TypeSelection = () => {
  const history = useHistory();
  const [type, setType] = useState("org");
  const [btnStates, setBtnStates] = useState({
    failure: false,
    success: false,
    pending: false,
  });
  const typeHandler = useCallback((type) => {
    setType(type);
  }, []);
  return (
    <div className="flex items-center justify-center flex-col gap-[80px]">
      <Text
        className="!text-[32px] text-[#333333]"
        weight="semibold"
        label="How do you plan to use workspace?"
      />
      <div className="flex items-center justify-center gap-[24px]">
        <div
          className="user-select-cards flex flex-col items-center p-[24px] cursor-pointer"
          onClick={typeHandler.bind(null, "org")}
        >
          <div className="w-full flex items-center justify-end">
            {type === "org" ? IconTickPurple : IconUntick}
          </div>
          <div className="flex items-center justify-center mt-[12px]">
            {IconWorkerMale}
            {IconWorkerMale}
          </div>
          <Text
            className="mt-[24px] mb-[8px]"
            label="In my organization"
            weight="bold"
          />
          <div className="w-[227px] text-center">
            <Text
              label="Collaborate with different teams on various projects."
              size="sm"
              className="opacity-60 text-center"
            />
          </div>
        </div>

        <div
          className="user-select-cards flex flex-col items-center p-[24px] cursor-pointer"
          onClick={typeHandler.bind(null, "freelance")}
        >
          <div className="w-full flex items-center justify-end">
            {type === "freelance" ? IconTickPurple : IconUntick}
          </div>
          <div className="flex items-center justify-center mt-[12px]">
            {IconWorkerMale}
          </div>
          <Text
            className="mt-[24px] mb-[8px]"
            label="I’m a freelancer"
            weight="bold"
          />
          <div className="w-[227px] text-center">
            <Text
              label="Work as a freelancer and bill your clients with ease."
              size="sm"
              className="opacity-60 text-center"
            />
          </div>
        </div>
      </div>
      <form
        className="w-[480px]"
        onSubmit={(e) => {
          e.preventDefault();
          setBtnStates((b) => {
            return {
              ...b,
              pending: true,
            };
          });
          setTimeout(() => {
            setBtnStates((b) => {
              return {
                ...b,
                pending: false,
                success: true,
              };
            });
            setTimeout(() => {
              history.push("/work/setup/company");
            }, 1300);
          }, 1300);
        }}
      >
        <Button
          size="medium"
          loading={btnStates.pending}
          success={btnStates.success}
          failure={btnStates.failure}
          disabled={!type}
          full
          label="Continue"
        />
      </form>
    </div>
  );
};

export default TypeSelection;
