import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withFormik } from "formik";
import { Input, Text, Button, toast } from "samespace-zen";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";

import { Avatar } from "../../components";
import { SIGN_IN_WITH_OAUTH } from "./queries";
import { authClient } from "../../graphql/Provider";
import { SIGNIN, SSO_LOGIN } from "./queries";

import { uuidv4, validateEmail } from "../../utils/common";
import {
  IconBack,
  IconGoogle,
  IconLock,
  IconPasswordHide,
  IconPasswordShow,
  IconRightArrow,
  IconSamespace,
} from "../../utils/icons";
import { randomColorGenerator } from "../../utils/common";

import { setAuthenticationData } from "../../utils/auth";
import { AnimatePresence, motion } from "framer-motion";

import Platform from "platform";
const LoginForm = ({
  values,
  errors,
  touched,
  handleChange,
  validateForm,
  setTouched,
  setErrors,
}) => {
  const history = useHistory();
  const [btnStates, setBtnStates] = useState({
    loading: false,
    success: false,
    failure: false,
  });
  const [SAMLOrgs, setSAMLOrgs] = useState([]);
  const [ssoLoading, setSSOLoading] = useState(false);
  const [showSAMLForm, setShowSAMLForm] = useState(false);
  const [signInWithOAuth] = useMutation(SIGN_IN_WITH_OAUTH);
  const [SiginIn] = useMutation(SIGNIN);
  const [pageLoaded, setPageLoaded] = useState();
  const [passwordFieldType, setPasswordFieldType] = useState("password");
  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 300);
  }, []);
  const ssoLoginHandler = useCallback(
    async (e) => {
      e.preventDefault();
      setSSOLoading(true);
      if (validateEmail(values.email)) {
        try {
          const { data } = await authClient.query({
            query: SSO_LOGIN,
            variables: {
              email: values.email,
            },
          });
          if (data.ssoLogin) {
            if (Array.isArray(data.ssoLogin) && data.ssoLogin.length === 1) {
              window.location.href = `${data.ssoLogin[0].ssoUrl}&${
                window.location.search
                  ? window.location.search.substring(1)
                  : ""
              }`;
            } else {
              setSAMLOrgs(data.ssoLogin);
            }
          } else {
            toast.error("SSO Login is not enabled for your organization.", {
              position: "bottom-right",
            });
          }
        } catch (e) {
          setSSOLoading(false);
          toast.error(e.message || "Something went wrong", {
            position: "bottom-right",
          });
        }
      }
    },
    [values]
  );
  const redirectHandler = useCallback(() => {
    history.push("/forgot-password");
  }, [history]);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      validateForm().then(async (errors) => {
        setTouched({ ...errors });
        setErrors({ ...errors });
        if (Object.keys(errors).length === 0) {
          setBtnStates({
            ...btnStates,
            loading: true,
          });
          try {
            const { data } = await SiginIn({
              variables: {
                email: values.email,
                password: values.password,
                device: {
                  id: uuidv4(),
                  name: `${Platform.name},${Platform.os.toString()}`,
                  version: Platform.os.version,
                  appName: "WEB_APP",
                  appVersion: "1",
                },
              },
            });
            setAuthenticationData(data, "signin");
          } catch (e) {
            console.log(e);
            toast.error(e.message || "Something went wrong");
            setBtnStates({
              ...btnStates,
              failure: true,
              loading: false,
            });
            setTimeout(() => {
              setBtnStates({
                ...btnStates,
                failure: false,
                loading: false,
              });
            }, 3000);
          }
        }
      });
    },
    [validateForm, values, SiginIn, setErrors, setTouched, btnStates]
  );
  return (
    <AnimatePresence>
      <motion.form
        exit={{
          opacity: 0,
        }}
        onSubmit={submitForm}
        className="flex flex-col items-center"
      >
        <div className="mb-[32px]">{IconSamespace}</div>

        {SAMLOrgs.length > 0 && (
          <motion.div
            key="saml-orgs"
            initial={{
              opacity: 0,
              x: -100,
            }}
            animate={{
              x: 0,
              opacity: 1,

              transition: { duration: 0.4, ease: "easeIn" },
            }}
            exit={{
              x: -100,
              opacity: 0,
              transition: { duration: 0.4, ease: "easeIn" },
            }}
          >
            <div className="text-sm opacity-60 mb-2 text-left">
              Continue With
            </div>
            <div className="w-[400px] org-list max-h-[600] overflow-y-auto">
              {SAMLOrgs.map((item) => {
                return (
                  <div
                    className="org-item w-full justify-between"
                    onClick={() =>
                      (window.location.href = `${item.ssoUrl}&${
                        window.location.search
                          ? window.location.search.substring(1)
                          : ""
                      }`)
                    }
                  >
                    <div className="flex flex-col items-start">
                      <div>
                        <div className="flex items-center">
                          <Avatar
                            size="regular"
                            label={item.orgName.substring(0, 1)}
                            color={randomColorGenerator(
                              Math.floor(Math.random() * 6)
                            )}
                          />
                          <div className="flex flex-col items-start ml-2">
                            <div className="font-bold text-base">
                              {item.orgName}
                            </div>
                            <div className="text-sm opacity-60">
                              {item.orgId}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="opacity-60">{IconRightArrow}</div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        )}
        {SAMLOrgs.length === 0 && (
          <AnimatePresence exitBeforeEnter>
            {showSAMLForm && (
              <motion.div
                key="saml-form"
                initial={{
                  x: 100,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: { duration: 0.4, ease: "easeIn" },
                }}
                exit={{
                  x: 100,
                  opacity: 0,
                  transition: { duration: 0.4, ease: "easeIn" },
                }}
              >
                <div className="w-[400px] h-[344px] text-left flex flex-col items-center justify-center">
                  <div className="w-[400px] text-left">
                    <Input
                      autoFocus
                      type="text"
                      name="email"
                      label="Email"
                      value={values.email}
                      errorText={errors.email}
                      error={touched.email && errors.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full flex flex-col items-center mt-[32px]">
                    <div
                      className="w-full flex flex-col items-center"
                      key="saml-form"
                      initial={{
                        x: 100,
                        opacity: 0,
                      }}
                      animate={{
                        x: 0,
                        opacity: 1,
                        transition: { duration: 0.4, ease: "easeIn" },
                      }}
                      exit={{
                        x: 100,
                        opacity: 0,
                        transition: { duration: 0.4, ease: "easeIn" },
                      }}
                    >
                      <Button
                        full
                        shape="rounded"
                        loading={ssoLoading}
                        disabled={!validateEmail(values.email)}
                        onClick={ssoLoginHandler}
                        className="mt-2"
                        label="Continue with SAML"
                      />
                      <div
                        onClick={() => {
                          if (!ssoLoading) {
                            setShowSAMLForm(false);
                          }
                        }}
                        className="w-full p-2 hover:bg-gray-200 mt-4 cursor-pointer rounded-full flex items-center justify-center"
                      >
                        <Text
                          size="sm"
                          icon={
                            <div className="-rotate-180 flex items-center justify-center">
                              {IconBack}
                            </div>
                          }
                          className="text-sm opacity-50"
                          label="Back to login"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
            {!showSAMLForm && (
              <motion.div
                key="login-form"
                initial={
                  !pageLoaded
                    ? {
                        opacity: 0,
                      }
                    : {
                        x: -100,
                        opacity: 0,
                      }
                }
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: { duration: 0.4, ease: "easeIn" },
                }}
                exit={{
                  x: -100,
                  opacity: 0,
                  transition: { duration: 0.4, ease: "easeIn" },
                }}
              >
                <div className="w-[400px] text-left mb-[32px]">
                  <Input
                    autoFocus
                    type="text"
                    name="email"
                    label="Email"
                    value={values.email}
                    errorText={errors.email}
                    error={touched.email && errors.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-[400px] text-left mb-[32px] relative">
                  <span className="absolute right-0 cursor-pointer z-10">
                    <Text
                      onClick={redirectHandler}
                      size="xs"
                      color="primary"
                      label="Forgot?"
                      className="hover:underline"
                    />
                  </span>
                  <Input
                    name="password"
                    label="Password"
                    inputClassName={
                      passwordFieldType === "password" && "font-serif"
                    }
                    type={passwordFieldType}
                    value={values.password}
                    onChange={handleChange}
                    errorText={errors.password}
                    error={touched.password && errors.password}
                    suffix={
                      values.password && (
                        <div
                          className="flex items-center justify-between cursor-pointer opacity-20"
                          onClick={() => {
                            if (passwordFieldType === "password") {
                              setPasswordFieldType("text");
                            } else {
                              setPasswordFieldType("password");
                            }
                          }}
                        >
                          {passwordFieldType === "text"
                            ? IconPasswordHide
                            : IconPasswordShow}
                        </div>
                      )
                    }
                  />
                </div>
                <div className="w-full flex flex-col items-center">
                  <div
                    className="w-full flex flex-col items-center"
                    key="login-form"
                    initial={
                      !pageLoaded
                        ? {
                            opacity: 0,
                          }
                        : {
                            x: -100,
                            opacity: 0,
                          }
                    }
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: { duration: 0.4, ease: "easeIn" },
                    }}
                    exit={{
                      x: -100,
                      opacity: 0,
                      transition: { duration: 0.4, ease: "easeIn" },
                    }}
                  >
                    <Button
                      label="Sign In"
                      full
                      className="submit-btn"
                      type="submit"
                      shape="rounded"
                      loading={btnStates.loading}
                      success={btnStates.success}
                      failure={btnStates.failure}
                    />
                    <div className="w-full or-divider my-[32px] relative">
                      <div className="or-text">OR</div>
                    </div>

                    <div className="flex  w-full items-center gap-x-8">
                      <Button
                        full
                        type="button"
                        onClick={() =>
                          signInWithOAuth({
                            variables: {
                              payload: {
                                params: window.location.search
                                  ? window.location.search.substring(1)
                                  : "",
                                device: {
                                  id: uuidv4(),
                                  name: `${
                                    Platform.name
                                  },${Platform.os.toString()}`,
                                  version: Platform.os.version,
                                  appName: "WEB_APP",
                                  appVersion: "1",
                                },
                                provider: "GOOGLE",
                              },
                            },
                          }).then(({ data }) => {
                            window.location = data.signInWithOAuth;
                          })
                        }
                        accent="contrast"
                        appearance="muted"
                        shape="rounded"
                        icon={IconGoogle}
                        label="Google"
                      />
                      <Button
                        full
                        onClick={() => {
                          setShowSAMLForm(true);
                        }}
                        type="button"
                        accent="contrast"
                        appearance="muted"
                        shape="rounded"
                        icon={IconLock}
                        label="Single Sign-On"
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        )}
        <div className="absolute bottom-4">
          <Text
            label="Copyright © 2022 Samespace Inc."
            color="muted"
            size="xs"
          />
        </div>
      </motion.form>
    </AnimatePresence>
  );
};

export default withFormik({
  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    email: Yup.string().email("Enter a valid Email").required("Required"),
    password: Yup.string().required("Required"),
  }),
})(LoginForm);
