import { gql } from "@apollo/client";

export const SIGN_IN_WITH_OAUTH = gql`
  mutation SignInWithOAuth($payload: inputsignInWithOAuth!) {
    signInWithOAuth(payload: $payload)
  }
`;

export const SIGNIN = gql`
  mutation Signin($email: Email!, $password: String!, $device: DeviceInput) {
    signin(email: $email, password: $password, device: $device)
  }
`;

export const SSO_LOGIN = gql`
  query ssoLogin($email: Email!) {
    ssoLogin(email: $email)
  }
`;
