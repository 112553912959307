import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import axios from "axios";

import classNames from "classnames";

import { IconCamera } from "../Header/icons";
import { getCookie } from "../../utils/cookies";
import { getDomain } from "../../utils/common";

import { toast } from "samespace-zen";

const AvatarUpload = forwardRef(
  ({ label, size, color, userId, url, refetchProfile }, ref) => {
    const [showAvatar, setShowAvatar] = useState(false);
    const [files, setFiles] = useState([]);
    const [imageUploaded, setImageUploaded] = useState(false);
    const [image, setImage] = useState(`${url}?width=40&height=40`);
    const uploadImage = useCallback(
      async (files) => {
        const formData = new FormData();
        formData.append("profilePic", files);
        if (files) {
          const upload = axios.post(
            `https://api.${getDomain()}/signin/api/profile/upload/user`,
            formData,
            {
              headers: {
                Authorization: getCookie("orgToken"),
              },
            }
          );
          toast.promise(
            upload,
            {
              loading: "Uploading...",
              success: <b>Image Uploaded!</b>,
              error: <b>Could not upload image.</b>,
            },
            {
              position: "top-center",
            }
          );
          upload.then(() => refetchProfile && refetchProfile());
        } else return;
      },
      [refetchProfile]
    );
    useImperativeHandle(ref, () => ({
      uploadImage: uploadImage,
      files: files,
      image: image,
      imageUploaded: imageUploaded,
    }));
    const imageErrorHandler = useCallback(() => {
      setShowAvatar(true);
    }, []);
    const imageChangeHandler = useCallback(
      (e) => {
        setFiles(e.target.files[0]);
        setShowAvatar(false);
        setImage(URL.createObjectURL(e.target.files[0]));
        setImageUploaded(true);
        uploadImage(e.target.files[0]);
      },
      [uploadImage]
    );
    const dragEnterHandler = useCallback((e) => {
      e.stopPropagation();
      e.preventDefault();
    }, []);
    const dragOverHandler = useCallback((e) => {
      e.stopPropagation();
      e.preventDefault();
    }, []);
    const dropHandler = useCallback((e) => {
      e.stopPropagation();
      e.preventDefault();
      const fileInput = document.querySelector("#fileInput");
      fileInput.files = e.dataTransfer.files;
      const dT = new DataTransfer();
      dT.items.add(e.dataTransfer.files[0]);
      setImage(URL.createObjectURL(dT.files[0]));
      fileInput.files = dT.files;
    }, []);
    const clickHandler = useCallback((e) => {
      const fileInput = document.querySelector("#fileInput");
      fileInput.click();
    }, []);
    return (
      <div
        className={classNames(
          "flex items-center justify-center rounded-full bg-opacity-10 text-skin-primary relative user-picture-container",
          {
            "h-[20px] w-[20px]": size === "xsmall",
            "h-[32px] w-[32px]": size === "small",
            "h-[40px] w-[40px]": size === "regular",
            "h-[48px] w-[48px]": size === "large",
            "h-[80px] w-[80px]": size === "xlarge",
            "h-full w-full": !size,
          }
        )}
      >
        <input
          type="file"
          accept="image/*"
          className="hidden"
          id="fileInput"
          onChange={imageChangeHandler}
        />
        {!showAvatar && userId && (
          <div
            onClick={clickHandler}
            onDragOver={dragOverHandler}
            onDragEnter={dragEnterHandler}
            onDrop={dropHandler}
            className={classNames(
              "flex items-center justify-center rounded-full bg-opacity-10 text-skin-primary relative user-picture-container object-cover",
              {
                "h-[20px] w-[20px]": size === "xsmall",
                "h-[32px] w-[32px]": size === "small",
                "h-[40px] w-[40px]": size === "regular",
                "h-[48px] w-[48px]": size === "large",
                "h-[80px] w-[80px]": size === "xlarge",
                "h-full w-full": !size,
              }
            )}
          >
            <div
              className={classNames(
                "camera-backdrop cursor-pointer items-center justify-center rounded-full z-5",
                {
                  "h-[20px] w-[20px]": size === "xsmall",
                  "h-[32px] w-[32px]": size === "small",
                  "h-[40px] w-[40px]": size === "regular",
                  "h-[48px] w-[48px]": size === "large",
                  "h-[80px] w-[80px]": size === "xlarge",
                  "h-full w-full": !size,
                }
              )}
            >
              {IconCamera}
            </div>
            <img
              className={classNames(
                "flex items-center justify-center rounded-full bg-opacity-10 text-skin-primary relative user-picture-container object-cover",
                {
                  "h-[20px] w-[20px]": size === "xsmall",
                  "h-[32px] w-[32px]": size === "small",
                  "h-[40px] w-[40px]": size === "regular",
                  "h-[48px] w-[48px]": size === "large",
                  "h-[80px] w-[80px]": size === "xlarge",
                  "h-full w-full": !size,
                }
              )}
              id={userId}
              alt=""
              onError={imageErrorHandler}
              src={image}
            />
          </div>
        )}
        {(showAvatar || !userId) && (
          <div
            onClick={clickHandler}
            onDragOver={dragOverHandler}
            onDragEnter={dragEnterHandler}
            onDrop={dropHandler}
            id="dropContainer"
            style={{
              backgroundColor: `rgba(${color}, 0.1)`,
            }}
            className={classNames(
              "flex items-center justify-center rounded-full bg-opacity-10 text-skin-primary user-picture-container relative object-cover",
              {
                "h-[20px] w-[20px]": size === "xsmall",
                "h-[32px] w-[32px]": size === "small",
                "h-[40px] w-[40px]": size === "regular",
                "h-[48px] w-[48px]": size === "large",
                "h-[80px] w-[80px]": size === "xlarge",
                "h-full w-full": !size,
              }
            )}
          >
            <div
              className={classNames(
                "camera-backdrop cursor-pointer items-center justify-center rounded-full z-5",
                {
                  "h-[20px] w-[20px]": size === "xsmall",
                  "h-[32px] w-[32px]": size === "small",
                  "h-[40px] w-[40px]": size === "regular",
                  "h-[48px] w-[48px]": size === "large",
                  "h-[80px] w-[80px]": size === "xlarge",
                  "h-full w-full": !size,
                }
              )}
            >
              {IconCamera}
            </div>
            <div
              style={{
                color: `rgb(${color})`,
              }}
            >
              {label}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default AvatarUpload;
