import { useState, useCallback } from "react";
import { Text, Input, Select, Button } from "samespace-zen";
import {
  INDUSTRY_OPTIONS,
  SIZE_OPTIONS,
  URL_REGEX,
} from "../../../utils/constants";

import { useHistory } from "react-router-dom";
import { setAuthenticationData } from "../../../utils/auth";

import { CREATE_ORG } from "./queries";
import { useMutation } from "@apollo/client";
import { getCookie, setCookie } from "../../../utils/cookies";
import { getSearchParams } from "../../../utils/location";

const CompanyForm = () => {
  const history = useHistory();
  const [btnStates, setBtnStates] = useState({
    failure: false,
    success: false,
    pending: false,
  });
  const [payload, setPayload] = useState({
    name: "",
    website: "",
    size: "",
    industry: "",
  });
  const onChangeHandler = useCallback((e) => {
    setPayload((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const [createOrg] = useMutation(CREATE_ORG, {
    context: {
      headers: {
        authorization: getCookie("userToken"),
      },
    },
  });
  const disabledButtonHandler = useCallback(() => {
    if (
      payload.name.length >= 4 &&
      payload.size &&
      payload.industry &&
      URL_REGEX.test(payload.website)
    ) {
      return false;
    } else {
      return true;
    }
  }, [payload]);

  const submitFormHandler = useCallback(() => {
    setBtnStates((b) => {
      return {
        ...b,
        pending: true,
      };
    });
    createOrg({
      variables: {
        payload: {
          ...payload,
          size: payload.size.value,
          industry: payload.industry.value,
          product: "Workspace",
        },
      },
    })
      .then(({ data }) => {
        const session = data.createOrg.session;
        if (session) {
          const agent = getSearchParams("agent");
          const sessionId = session.sessionId || getSearchParams("session");
          const user = session.user;
          const accounts = session.accounts;
          setCookie("sessionId", sessionId);
          setCookie("user", JSON.stringify(user));
          const organizations = {};
          accounts.forEach((acc) => {
            organizations[acc.orgId] = acc;
          });
          setCookie("organizations", JSON.stringify(organizations));
          let activeOrg;
          let activeSpaceObj;
          if (getSearchParams("orgId")) {
            organizations[getSearchParams("orgId")]
              ? (activeOrg = getSearchParams("orgId"))
              : (activeOrg = Object.keys(organizations)[0]);
          } else {
            if (["work", "dock", "sf_dock"].includes(agent)) {
              activeOrg = Object.keys(organizations).find((item) =>
                organizations[item].spaces?.find(
                  (space) =>
                    space.productName ===
                    (agent === "dock" || agent === "sf_dock"
                      ? "Samespace"
                      : "Workspace")
                )
              );
              if (!activeOrg) {
                activeOrg = Object.keys(organizations)[0];
              }
            } else {
              activeOrg = Object.keys(organizations)[0];
            }
          }
          setCookie("activeOrg", activeOrg);
          if (getSearchParams("spaceId") && organizations[activeOrg].spaces) {
            const activeSpaceIndex = organizations[activeOrg].spaces.findIndex(
              (space) => space.spaceId === getSearchParams("spaceId")
            );
            if (activeSpaceIndex > -1) {
              activeSpaceObj =
                organizations[activeOrg].spaces[activeSpaceIndex];
            } else {
              activeSpaceObj = organizations[activeOrg].spaces[0] || {};
            }
          } else {
            if (["work", "dock"].includes(agent)) {
              activeSpaceObj = organizations[activeOrg].spaces?.find(
                (space) =>
                  space.productName ===
                  (agent === "dock" || agent === "sf_dock"
                    ? "Samespace"
                    : "Workspace")
              );
              if (!activeSpaceObj) {
                activeSpaceObj =
                  organizations[activeOrg].spaces &&
                  organizations[activeOrg].spaces.length > 0
                    ? organizations[activeOrg].spaces[0]
                    : {};
              }
            } else {
              activeSpaceObj =
                organizations[activeOrg].spaces &&
                organizations[activeOrg].spaces.length > 0
                  ? organizations[activeOrg].spaces[0]
                  : {};
            }
          }
          const activeSpace = activeSpaceObj.spaceId;

          if (activeSpace) {
            setCookie("activeSpace", activeSpace);
          }
        }

        const tokens = data.createOrg.tokens || [];
        if (tokens && tokens.length > 0) {
          const sso = tokens.find((sess) => sess.type === "sso");
          if (sso) {
            return (window.location.href = `${sso.value}&${
              window.location.search ? window.location.search.substring(1) : ""
            }`);
          }

          const orgToken = tokens.find((sess) => sess.type === "org") || {};
          if (orgToken.value) {
            setCookie("orgToken", orgToken.value);
          }
          const spaceToken = tokens.find((sess) => sess.type === "space");
          if (spaceToken) {
            setCookie("spaceToken", spaceToken.value);
            setCookie("appVersion", spaceToken.version || "");
          }
        }
        setTimeout(() => {
          history.push("/work/setup/team");
        }, 1300);

        setBtnStates((b) => {
          return {
            ...b,
            success: true,
          };
        });
      })
      .catch((e) => {
        setBtnStates((b) => {
          return {
            ...b,
            failure: true,
            pending: false,
          };
        });
        setTimeout(() => {
          setBtnStates((b) => {
            return {
              ...b,
              failure: false,
              pending: false,
            };
          });
        }, 2000);
      });
  }, [createOrg, payload, history]);
  return (
    <>
      <Text
        label="Your Organization"
        weight="semibold"
        className="!text-[32px] text-[#333333]"
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitFormHandler();
        }}
        className="flex flex-col gap-y-8 w-full"
      >
        <Input
          size="medium"
          autoFocus
          label="Name"
          name="name"
          value={payload.name}
          onChange={onChangeHandler}
        />
        <Select
          size="medium"
          label="Industry"
          options={INDUSTRY_OPTIONS.map((item) => {
            return {
              value: item,
              label: item,
            };
          })}
          name="industry"
          value={payload.industry}
          onChange={onChangeHandler}
        />

        <Select
          size="medium"
          label="No. of Employees"
          options={SIZE_OPTIONS.map((item) => {
            return {
              value: item,
              label: item,
            };
          })}
          name="size"
          value={payload.size}
          onChange={onChangeHandler}
        />
        <Input
          size="medium"
          value={payload.website}
          label="Website"
          name="website"
          onChange={onChangeHandler}
        />

        <Button
          size="medium"
          type="submit"
          loading={btnStates.pending}
          success={btnStates.success}
          failure={btnStates.failure}
          label="Continue"
          disabled={disabledButtonHandler()}
          full
        />
      </form>
    </>
  );
};

export default CompanyForm;
