export const BASE_SAMESPACE_API = ".samespace.com/auth/api/accounts";

export const avatarColors = [
  "124,58,237",
  "219,39,119",
  "79,70,229",
  "37,99,235",
  "5,150,105",
  "217,119,6",
  "234,88,12",
  "13,148,136",
  "8,145,178",
  "225,29,72",
];

export const WORK_ID = "Workspace";
export const CONNECT_ID = "Samespace";
export const DISCOVER_ID = "Discover";

export const products = [
  {
    id: WORK_ID,
    path: "work",
    title: "Work",
    description: "Take employee productivity to the next level.",
    icon: (
      <svg
        width="39"
        height="34"
        viewBox="0 0 39 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9257 13.2C11.8535 13.0162 11.8273 12.8176 11.8494 12.6214C11.8714 12.4251 11.9411 12.2373 12.0522 12.0741C12.1634 11.9109 12.3128 11.7773 12.4873 11.6849C12.6618 11.5926 12.8563 11.5443 13.0537 11.5441H28.0663C28.0663 11.8447 28.0101 12.1462 27.8979 12.4306L24.5623 20.9165C24.3841 21.3696 24.0736 21.7586 23.6712 22.0327C23.2688 22.3069 22.7932 22.4535 22.3063 22.4535H15.4734C15.4734 22.3032 15.4449 22.153 15.3887 22.0103L11.9247 13.2H11.9257Z"
          fill="url(#paint0_linear_178_227)"
        />
        <path
          d="M1.01756 2.29106C0.945592 2.10731 0.91955 1.90877 0.941701 1.71268C0.963851 1.51658 1.03352 1.32885 1.14467 1.16578C1.25582 1.00272 1.40508 0.86924 1.57951 0.776936C1.75394 0.684632 1.94825 0.636291 2.1456 0.636108H14.9135L14.9658 0.637059L15.039 0.636108H22.3056C22.7926 0.636069 23.2684 0.782662 23.671 1.0568C24.0735 1.33094 24.3842 1.71993 24.5626 2.17312L27.8992 10.659C28.0104 10.9444 28.0666 11.2449 28.0666 11.5464H13.055C12.2009 11.5464 11.615 12.4063 11.9269 13.2014L15.3909 22.0126C15.448 22.1553 15.4756 22.3056 15.4756 22.4558H2.1456C1.94817 22.4559 1.75373 22.4077 1.57918 22.3154C1.40462 22.2232 1.25526 22.0897 1.14406 21.9266C1.03287 21.7634 0.96322 21.5756 0.941173 21.3794C0.919127 21.1832 0.945351 20.9846 1.01756 20.8009L4.48156 11.9897C4.59379 11.7043 4.59379 11.3876 4.48156 11.1023L1.01756 2.29201V2.29106Z"
          fill="#00FFF0"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.4734 22.4545C15.4734 22.6057 15.4458 22.756 15.3897 22.8986L11.9257 31.7079C11.8535 31.8917 11.8273 32.0904 11.8494 32.2866C11.8714 32.4828 11.9411 32.6707 12.0522 32.8339C12.1634 32.9971 12.3128 33.1307 12.4873 33.223C12.6618 33.3154 12.8563 33.3637 13.0537 33.3639H33.2147C33.7017 33.3639 34.1775 33.2173 34.5801 32.9432C34.9826 32.669 35.2933 32.28 35.4717 31.8268L38.8073 23.3409C39.0318 22.7702 39.0318 22.1368 38.8073 21.5671L35.4717 13.0811C35.2933 12.628 34.9826 12.239 34.5801 11.9648C34.1775 11.6907 33.7017 11.5441 33.2147 11.5441H28.0663C28.0663 11.8447 28.0101 12.1462 27.8979 12.4306L24.5623 20.9165C24.3841 21.3696 24.0736 21.7586 23.6712 22.0327C23.2688 22.3069 22.7932 22.4535 22.3063 22.4535H15.4734V22.4545Z"
          fill="#0088FF"
        />
        <defs>
          <linearGradient
            id="paint0_linear_178_227"
            x1="14.3054"
            y1="21.2418"
            x2="28.8528"
            y2="9.72558"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0048E5" />
            <stop offset="0.64" stop-color="#625AF5" />
            <stop offset="1" stop-color="#8A62FC" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  {
    id: CONNECT_ID,
    path: "connect",
    title: "Connect",
    description: "Automate support teams and increase sales productivity.",
    icon: (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_178_217)">
          <path
            d="M10.7911 0.644598C5.18653 0.883508 0.728261 5.41951 0.594897 11.0175C0.460716 16.6147 4.69726 21.3577 10.2838 21.8633C10.5882 21.8887 10.8925 21.9091 11.2051 21.9091H19.8353C20.9562 21.9091 21.8644 21.0018 21.8644 19.8833V11.281C21.8653 9.84781 21.5764 8.42927 21.0149 7.11066C20.4535 5.79206 19.6311 4.60064 18.5974 3.60805C17.5625 2.61452 16.3378 1.83997 14.9967 1.33078C13.6555 0.821579 12.2254 0.588198 10.7919 0.644598H10.7911Z"
            fill="url(#paint0_linear_178_217)"
          />
          <path
            d="M23.1182 33.3532C28.7227 33.1143 33.181 28.5783 33.3144 22.9803C33.4486 17.3831 29.212 12.6401 23.6255 12.1345C23.3211 12.1091 23.0167 12.0887 22.7042 12.0887H14.074C13.8078 12.0885 13.5441 12.1407 13.298 12.2424C13.052 12.3441 12.8283 12.4932 12.6399 12.6814C12.4515 12.8695 12.302 13.0929 12.1999 13.3388C12.0978 13.5847 12.0451 13.8483 12.0449 14.1145V22.7169C12.044 24.15 12.3329 25.5686 12.8944 26.8872C13.4558 28.2058 14.2781 29.3972 15.3119 30.3898C17.4016 32.3968 20.221 33.4661 23.1174 33.3532H23.1182Z"
            fill="#6646E5"
          />
          <path
            d="M21.8631 12.0887V19.881C21.8629 20.1473 21.8102 20.4109 21.7081 20.6568C21.6061 20.9027 21.4565 21.1261 21.2681 21.3142C21.0797 21.5023 20.8561 21.6515 20.61 21.7532C20.3639 21.8549 20.1003 21.9071 19.834 21.9069H12.0449V14.1145C12.0455 13.6 12.2417 13.1049 12.5937 12.7297C12.9457 12.3544 13.4272 12.127 13.9406 12.0936L14.074 12.0887H21.8631Z"
            fill="url(#paint1_linear_178_217)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_178_217"
            x1="11.228"
            y1="2.03305"
            x2="11.228"
            y2="13.117"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EF118B" />
            <stop offset="0.33" stop-color="#E81562" />
            <stop offset="0.74" stop-color="#E01F73" />
            <stop offset="1" stop-color="#E021B0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_178_217"
            x1="16.954"
            y1="13.4992"
            x2="16.954"
            y2="22.9222"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#58009C" />
            <stop offset="1" stop-color="#7300E6" />
          </linearGradient>
          <clipPath id="clip0_178_217">
            <rect
              width="32.7273"
              height="32.7273"
              fill="white"
              transform="translate(0.591797 0.636353)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },

  {
    id: DISCOVER_ID,
    path: "discover",
    title: "Discover",
    description:
      "AI powered actionable insights across all communication channels.",
    icon: (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_178_232)">
          <path
            d="M20.9411 4.28945C20.8347 4.20174 20.7119 4.13617 20.5799 4.09661C20.4478 4.05704 20.3092 4.04428 20.1721 4.05907C20.035 4.07386 19.9023 4.1159 19.7818 4.18272C19.6612 4.24954 19.5552 4.3398 19.47 4.44818L15.6957 9.21327L3.39844 24.7342C3.6218 24.9109 3.83616 25.1007 4.03907 25.3012C4.08816 25.347 4.13153 25.3928 4.17735 25.4411C4.46371 25.734 4.72716 26.049 4.96362 26.3836L5.04053 26.4933C5.28598 26.8525 5.50116 27.2313 5.68116 27.6273C5.68935 27.6387 5.69589 27.651 5.70162 27.6633L5.69916 27.6608C5.93316 28.1714 6.11071 28.7056 6.22771 29.2546C6.33214 29.7068 6.58738 30.1099 6.95141 30.3976C7.31545 30.6854 7.7666 30.8406 8.23062 30.8378H32.2606C32.3988 30.8377 32.5355 30.8101 32.6629 30.7566C32.7903 30.7031 32.9057 30.6247 33.0024 30.526C33.0992 30.4274 33.1753 30.3105 33.2263 30.1821C33.2774 30.0537 33.3023 29.9164 33.2997 29.7783C33.1475 24.8997 31.9682 20.108 29.8389 15.7161C27.7095 11.3241 24.6779 7.4305 20.9419 4.28945H20.9411Z"
            fill="#13936E"
          />
          <path
            d="M23.3393 18.6224C21.5191 14.9589 18.9091 11.7446 15.6974 9.21089C12.667 6.83975 9.18388 5.11296 5.46198 4.13652C5.32546 4.10393 5.1838 4.09889 5.04531 4.1217C4.90682 4.14451 4.77426 4.19472 4.65541 4.26939C4.53655 4.34405 4.43379 4.44167 4.35312 4.55654C4.27245 4.6714 4.21551 4.8012 4.18562 4.93834L0.659252 21.419C0.551329 21.8841 0.606863 22.3722 0.816516 22.8012C1.02617 23.2302 1.37715 23.5739 1.81043 23.7745C2.37589 24.0309 2.90852 24.3542 3.39689 24.7375C3.62025 24.9151 3.83462 25.1041 4.03752 25.3045C4.08662 25.3503 4.12916 25.3962 4.1758 25.4453C4.46298 25.7365 4.72725 26.0507 4.96452 26.3845L5.04143 26.495C5.28689 26.8533 5.50207 27.233 5.68207 27.629C5.69014 27.6399 5.697 27.6517 5.70252 27.6642L22.8295 20.0436C22.9575 19.9852 23.0724 19.9018 23.1677 19.7983C23.2629 19.6948 23.3365 19.5734 23.3841 19.4411C23.4318 19.3087 23.4525 19.1682 23.4451 19.0278C23.4376 18.8873 23.4022 18.7498 23.3409 18.6233L23.3393 18.6224Z"
            fill="url(#paint0_linear_178_232)"
          />
          <path
            d="M15.6982 9.21088L16.0803 9.51851C19.1182 12.004 21.5926 15.1073 23.3392 18.6224C23.4005 18.7489 23.436 18.8864 23.4435 19.0268C23.451 19.1672 23.4303 19.3077 23.3827 19.44C23.3352 19.5723 23.2617 19.6938 23.1665 19.7973C23.0714 19.9008 22.9565 19.9843 22.8286 20.0428L5.70162 27.6633C5.69578 27.6508 5.68894 27.6388 5.68116 27.6273C5.50092 27.2317 5.28656 26.8526 5.04053 26.4941L4.96362 26.3837L4.78116 26.1366C4.59394 25.8929 4.39231 25.6607 4.17735 25.4411C3.93365 25.1883 3.67345 24.9518 3.39844 24.7334L15.6957 9.21333L15.6982 9.21088Z"
            fill="url(#paint1_linear_178_232)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_178_232"
            x1="12.0271"
            y1="4.10216"
            x2="12.0271"
            y2="27.6633"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.26" stop-color="#FF9000" />
            <stop offset="0.91" stop-color="#E05A30" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_178_232"
            x1="13.422"
            y1="9.21088"
            x2="13.422"
            y2="27.6633"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F5B000" />
            <stop offset="1" stop-color="#FE8F60" />
          </linearGradient>
          <clipPath id="clip0_178_232">
            <rect
              width="32.7273"
              height="32.7273"
              fill="white"
              transform="translate(0.591797 0.636353)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];

export const ENTITY_COLORS_APP = [
  "#5345F6",
  "#AD00FF",
  "#0069FF",
  "#106D5D",
  "#111111",
];

export const SPECIAL_CHARACTER_REGEX =
  /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

export const URL_REGEX =
  /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;

export const SIZE_OPTIONS = ["1-10", "11-25", "26-50", "51-100", "100+"];

export const INDUSTRY_OPTIONS = [
  "Computer Software",
  "Consumer Goods",
  "Food Production",
  "Non-Profit Organization Management",
  "Computer Software & Hardware",
  "Apparel & Fashion",
  "Financial Services",
  "Outsourcing/Offshoring",
  "Telecommunications",
  "Hospital & Health Care",
  "Marketing and Advertising",
  "Automotive",
  "Insurance",
  "Retail",
  "Human Resources",
  "Staffing and Recruiting",
  "Health, Wellness and Fitness",
  "Financial Services",
  "Entertainment",
  "Telecommunications",
  "Computer Hardware",
  "Higher Education",
  "Apparel & Fashion",
];
