import { useEffect } from "react";
import { LoaderWrapper } from "./LoaderWrapper";

const SFDockLoading = () => {
  useEffect(() => {
    setTimeout(() => {
      window.close();
    }, 1000);
  }, []);

  return <LoaderWrapper />;
};

export default SFDockLoading;
