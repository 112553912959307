import Cookies from "universal-cookie";
import { getDomain } from "./common";

const cookie = new Cookies();
const COOKIE_EXPIRY_IN_SEC = 30 * 24 * 3600 * 1000;
export const COOKIE_DOMAIN = `.${getDomain()}`;

export function getAllCookie() {
  return cookie.getAll();
}
export function getCookie(key) {
  return cookie.get(key);
}
export function setCookie(key, value) {
  cookie.set(key, value, {
    path: "/",
    maxAge: COOKIE_EXPIRY_IN_SEC,
    domain: COOKIE_DOMAIN,
    secure: true,
    sameSite: "none",
    encode: (v) => v,
  });
}
export function removeCookie(key) {
  cookie.remove(key, {
    path: "/",
    domain: COOKIE_DOMAIN,
  });
}
