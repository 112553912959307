export const DashIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 10H15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const TickMarkIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2381 6.06696C14.2136 6.04405 14.1851 6.02638 14.1541 6.01495C14.1231 6.00352 14.0903 5.99856 14.0574 6.00036C14.0246 6.00215 13.9924 6.01066 13.9627 6.0254C13.933 6.04014 13.9065 6.06082 13.8845 6.08626L8.71588 12.0333L6.0323 9.51478C6.00793 9.49183 5.97944 9.4741 5.94847 9.46261C5.9175 9.45112 5.88465 9.4461 5.8518 9.44782C5.81896 9.44954 5.78677 9.45799 5.75707 9.47266C5.72737 9.48733 5.70074 9.50795 5.67872 9.53334L5.06443 10.2392C5.04239 10.2646 5.02539 10.2942 5.01439 10.3265C5.00339 10.3587 4.99862 10.3928 5.00034 10.4269C5.00207 10.4611 5.01026 10.4945 5.02444 10.5254C5.03863 10.5562 5.05853 10.5838 5.08301 10.6067L8.62588 13.932C8.66002 13.9638 8.70212 13.9849 8.74731 13.9929C8.79092 14.0038 8.83656 14.0021 8.87934 13.9882C8.92212 13.9742 8.96044 13.9483 8.99017 13.9134L14.9359 7.07198C14.9802 7.02078 15.0031 6.95341 14.9997 6.88465C14.9962 6.8159 14.9666 6.75139 14.9173 6.70531L14.2381 6.06696V6.06696Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  );
};
