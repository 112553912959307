import React, { useCallback } from "react";

import { WorkspaceGray, IconTickBlack } from "../icons";

const Header = () => {
  const getActiveState = useCallback(() => {
    if (
      ["/work/setup/typeselect", "/work/setup/profile"].includes(
        new URL(window.location.href).pathname
      )
    ) {
      return {
        started: true,
        setup: false,
      };
    } else if (
      [
        "/work/setup/company",
        "/work/setup/team",
        "/wrok/setup/project",
        "/work/setup/invite",
        "/work/setup/download",
      ].includes(new URL(window.location.href).pathname)
    ) {
      return {
        started: true,
        setup: true,
      };
    } else {
      return {
        started: false,
        setup: false,
      };
    }
  }, []);
  return (
    <nav className="relative flex items-center justify-center setup-pages-header w-full">
      <div className=" left-[16px] absolute">{WorkspaceGray}</div>
      <div className="flex items-center self-end">
        <div className="navbar-chips font-medium navbar-chips-active flex justify-center">
          Sign Up
          <div className="mt-2 ml-2 tick-icon-navbar">{IconTickBlack}</div>
        </div>
        <div
          className={`navbar-chips font-medium flex justify-center ${
            getActiveState().started ? "navbar-chips-active" : ""
          }`}
        >
          Get Started
          {getActiveState().setup && (
            <div className="mt-2 ml-2 tick-icon-navbar">{IconTickBlack}</div>
          )}
        </div>
        <div
          className={`navbar-chips font-medium flex justify-center ${
            getActiveState().setup ? "navbar-chips-active" : ""
          }`}
        >
          Setup
        </div>
      </div>

      <div />
    </nav>
  );
};

export default Header;
