import React, { useEffect, useCallback } from "react";

import { Text, Button, Loader } from "samespace-zen";

import { IconWorkspace } from "../utils/icons";

import { getSearchParams } from "../utils/location";
import { getCookie } from "../utils/cookies";

const WorkspaceAppLogin = () => {
  const appRedirectHandler = useCallback(() => {
    const appProtocol = getSearchParams("appProtocol") || "workspace";
    window.location = `${appProtocol}://desktop-redirect?sessionId=${getCookie(
      "sessionId"
    )}`;
  }, []);
  useEffect(() => {
    if (getCookie("sessionId")) {
      appRedirectHandler();
    } else {
      window.location.href = `/login${window.location.search}`;
    }
  }, [appRedirectHandler]);

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center">
      {!getCookie("sessionId") ? (
        <Loader />
      ) : (
        <>
          {IconWorkspace}
          <Text className="mt-8 mb-10" size="2xl" label="Signing In..." />
          <Button
            shape="rounded"
            label="Open Workspace"
            className="w-[400px]"
            onClick={appRedirectHandler}
          />
        </>
      )}
    </div>
  );
};

export default WorkspaceAppLogin;
