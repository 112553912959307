import React, { useState, useCallback } from "react";
import classNames from "classnames";

export const Avatar = ({ url, label, size, color }) => {
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const imageErrorHandler = useCallback(() => {
    setShowPlaceholder(true);
  }, []);
  return (
    <>
      {!showPlaceholder && url && (
        <img
          alt=""
          id={url}
          onError={imageErrorHandler}
          src={`${url}`}
          className={classNames(
            "flex items-center justify-center rounded-full bg-opacity-10 text-skin-primary object-cover",
            {
              "h-[24px] w-[24px]": size === "xsmall",
              "h-[32px] w-[32px]": size === "small",
              "h-[40px] w-[40px]": size === "regular",
              "h-[48px] w-[48px]": size === "large",
              "h-full w-full": !size,
            }
          )}
        />
      )}
      {(showPlaceholder || !url) && (
        <div
          style={{
            backgroundColor: color ? `rgba(${color}, 0.1)` : "#159570",
          }}
          className={classNames(
            "flex items-center justify-center rounded-full bg-opacity-10 text-skin-primary",
            {
              "h-[24px] w-[24px]": size === "xsmall",
              "h-[32px] w-[32px]": size === "small",
              "h-[40px] w-[40px]": size === "regular",
              "h-[48px] w-[48px]": size === "large",
              "h-full w-full": !size,
            }
          )}
        >
          <div
            style={{
              color: color ? `rgb(${color})` : "white",
            }}
          >
            {label ? (
              label
            ) : (
              <svg
                transform={`scale(${size === "xsmall" ? "0.75" : "1"})`}
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 6.80438C7.58637 6.80438 8.88367 5.29315 8.88367 3.36767C8.88367 1.49589 7.57227 0 6 0C4.42773 0 3.10928 1.51123 3.11633 3.37534C3.11633 5.29315 4.41363 6.80438 6 6.80438ZM1.37485 14H10.6251C11.4853 14 12 13.5474 12 12.7956C12 10.8318 9.65217 8.14685 6 8.14685C2.34078 8.14685 0 10.8318 0 12.7956C0 13.5474 0.514689 14 1.37485 14Z"
                  fill="currentColor"
                />
              </svg>
            )}
          </div>
        </div>
      )}
    </>
  );
};
