import { IconInviteHeader } from "../../utils/icons";

const Header = () => {
  return (
    <nav className="h-[56px] border-b w-full flex items-center justify-center z-[100004] fixed bg-skin-base">
      <div>{IconInviteHeader}</div>
    </nav>
  );
};

export default Header;
