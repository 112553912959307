import { useState, useEffect, useCallback, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";

import {
  toast,
  Text,
  Input,
  Button,
  Sidebar,
  Modal,
  Loader,
} from "samespace-zen";

import OTPView from "react-otp-input";

import { IconProfile, IconColorChecked } from "./icons";

import "react-phone-input-2/lib/style.css";

import Autosuggest from "react-autosuggest";

import { hexToRgb } from "../../utils/common";

import {
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  UPDATE_USER_EMAIL,
  SEND_MAIL_FOR_VERIFICATION,
  GET_CITY_COUNTRY_MAP,
} from "./queries";

import { authClient as client } from "../../graphql/Provider";

import PasswordModal from "./PasswordModal";
import AvatarUpload from "./AvatarUpload";

import PhoneInput from "react-phone-input-2";
import { getCookie } from "../../utils/cookies";

import ProfileCover from "../../assets/images/profile-cover.png";

import Popover from "../Popover";

import { AnimatePresence, motion } from "framer-motion";
import { ENTITY_COLORS_APP } from "../../utils/constants";
import classNames from "classnames";
import { IconDOB, IconLocation } from "../../utils/icons";
import moment from "moment-timezone";

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const hexColorRegex = /^#([0-9a-f]{3}){1,2}$/i;

const ProfileEditor = ({ showProfile, onClose }) => {
  const profilePopRef = useRef();
  const phonePopRef = useRef();
  const colorPopRef = useRef();
  const [emailFormPhase, setEmailFormPhase] = useState(1);
  const [newEmail, setNewEmail] = useState("");
  const [locationSuggestion, setLocationSuggestion] = useState([]);
  const [showOtp, setShowOtp] = useState(false);
  const [oneTimePassword, setOneTimePassword] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const profile = useRef();
  const [isPhonePopupOpen, setIsPhonePopupOpen] = useState(false);
  const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false);
  const [isColorPopupOpen, setIsColorPopupOpen] = useState(false);
  const [sendOTP, { loading: otpLoading }] = useMutation(
    SEND_MAIL_FOR_VERIFICATION,
    {
      context: {
        headers: {
          Authorization: getCookie("orgToken"),
        },
      },
    }
  );
  const { data, loading, refetch } = useQuery(GET_USER_PROFILE, {
    skip: !showProfile,
    context: {
      headers: {
        Authorization: getCookie("orgToken"),
      },
    },
  });

  const [updateEmail] = useMutation(UPDATE_USER_EMAIL, {
    refetchQueries: ["GetUser"],
    context: {
      headers: {
        Authorization: getCookie("orgToken"),
      },
    },
  });
  const [updateProfile, { loading: profileUpdateLoading }] = useMutation(
    UPDATE_USER_PROFILE,
    {
      client,
      refetchQueries: ["GetUser"],
      context: {
        headers: {
          Authorization: getCookie("orgToken"),
        },
      },
    }
  );

  const [payload, setPayload] = useState({
    id: "",
    firstName: "",
    lastName: "",
    resetPasswordDate: null,
    email: "",
    phoneNumber: "",
    location: "",
    dob: "",
  });

  useEffect(() => {
    if (data && data.getUser) {
      setPayload({
        ...data.getUser,
        location: data.getUser.location || "",
        phoneNumber: data.getUser.phoneNumber || "",
      });
    }
  }, [data]);

  const onChangeHandler = useCallback(
    (e) => {
      setPayload({
        ...payload,
        [e.target.name]: e.target.value,
      });
    },
    [payload]
  );

  useEffect(() => {
    setOneTimePassword("");
    setShowOtp(false);
  }, [showProfile]);
  const showModalHandler = useCallback(() => {
    setShowPasswordModal(true);
  }, []);
  const hideModalHandler = useCallback(() => {
    setShowPasswordModal(false);
  }, []);

  const handleOTP = async (value) => {
    setOneTimePassword(value);
    if (value.length === 6) {
      try {
        await updateEmail({
          variables: {
            email: payload.email,
            code: +value,
          },
        });
        toast.success("Email Updated Successfully");
        setShowOtp(false);
        setNewEmail("");
        setEmailFormPhase(1);
      } catch (e) {
        console.log(e, "Invalid Code");
      }
    }
  };

  const suggestionFetcher = useCallback(async () => {
    const { data } = await client.query({
      query: GET_CITY_COUNTRY_MAP,
      context: {
        headers: {
          Authorization: getCookie("orgToken"),
        },
      },
      variables: {
        query: payload.location,
        limit: 10,
      },
    });

    if (data.getCityCountryMap) {
      setLocationSuggestion(data.getCityCountryMap);
    }
  }, [payload]);

  const updateEmailHandler = async () => {
    try {
      await sendOTP({
        variables: {
          email: newEmail,
          category: "UPDATE",
        },
      });
      toast.success("Code sent to your email");
      setEmailFormPhase(2);
    } catch (e) {
      toast.error(e.message || "Something Went Wrong!");
    }
  };

  const submitProfile = async () => {
    try {
      await updateProfile({
        variables: {
          payload: {
            firstName: payload.firstName,
            lastName: payload.lastName,
            location: payload.location,
            workProfile: payload.workProfile,
            dob: payload.dob,
          },
        },
      });
      toast.success("Profile Updated Successfully");
      profilePopRef.current.hide();
    } catch (e) {
      toast.error(e.message || "Something went wrong!");
    }
  };

  const submitPhone = async () => {
    try {
      await updateProfile({
        variables: {
          payload: {
            phoneNumber:
              payload.phoneNumber.charAt(0) === "+"
                ? payload.phoneNumber
                : `+${payload.phoneNumber}`,
          },
        },
      });
      toast.success("Profile Updated Successfully");
      phonePopRef.current.hide();
    } catch (e) {
      toast.error(e.message || "Something went wrong!");
    }
  };

  const submitTheme = async (item) => {
    try {
      const updateTheme = updateProfile({
        variables: {
          payload: {
            appTheme: item ? item : payload.appTheme,
          },
        },
      });

      toast.promise(
        updateTheme,
        {
          loading: "Changing Theme...",
          success: <b>Theme Changed!</b>,
          error: <b>Could not change theme.</b>,
        },
        {
          position: "top-center",
        }
      );
      updateTheme
        .then(() => {
          localStorage.setItem("THEME", item ? item : payload.appTheme);
          const body = document.querySelector(`body`);
          body?.style.setProperty(
            "--color-primary",
            hexToRgb(item ? item : payload.appTheme)
          );
          body?.style.setProperty(
            "--primary-rgb",
            hexToRgb(item ? item : payload.appTheme)
          );
          body?.style.setProperty("--primary", item ? item : payload.appTheme);
        })
        .catch((e) => {
          toast.error(e.message || "Something went wrong!");
        });
    } catch (e) {
      toast.error(e.message || "Something went wrong!");
    }
  };
  return (
    <>
      <Sidebar
        width={720}
        onClose={onClose}
        show={showProfile}
        disableScroll={
          isColorPopupOpen || isPhonePopupOpen || isProfilePopupOpen
        }
        headerBackgroundColor="primary"
        padding="none"
        header={
          data ? (
            <Text color="white" icon={IconProfile} label="Profile" />
          ) : null
        }
        body={
          <div className="bg-[#F2F2F2]  w-full h-full">
            {loading && (
              <div className="w-full h-full flex items-center justify-center">
                <Loader />
              </div>
            )}
            {data && (
              <>
                <Modal
                  backdropClassName="z-1002"
                  className="z-1004"
                  size="regular"
                  showClose={true}
                  showModal={showOtp}
                >
                  <div className={"flex flex-col items-center p-11"}>
                    <AnimatePresence exitBeforeEnter>
                      {emailFormPhase === 1 && (
                        <motion.div
                          animate={{
                            opacity: 1,
                          }}
                          exit={{
                            opacity: 0,
                          }}
                          className="w-full"
                          key="firstPhase"
                        >
                          <Text
                            weight="bold"
                            label="Enter your new E-mail"
                            className="mb-10"
                          />
                          <Input
                            autoFocus
                            placeholder="Email"
                            name="newEmail"
                            onChange={(e) => setNewEmail(e.target.value)}
                            value={newEmail}
                          />
                          <div className="w-full flex items-center justify-end mt-10 gap-x-4">
                            <Button
                              onClick={() => {
                                setNewEmail("");
                                setEmailFormPhase(1);
                                setShowOtp(false);
                              }}
                              shape="rounded"
                              accent="contrast"
                              appearance="muted"
                              label="Cancel"
                            />
                            <Button
                              disabled={!emailRegex.test(newEmail)}
                              loading={otpLoading}
                              shape="rounded"
                              label="Send Code"
                              onClick={updateEmailHandler}
                            />
                          </div>
                        </motion.div>
                      )}
                      {emailFormPhase === 2 && (
                        <motion.div
                          initial={{
                            opacity: 0,
                          }}
                          animate={{
                            opacity: 1,
                          }}
                          exit={{
                            opacity: 0,
                          }}
                          key="secondPhase"
                        >
                          <Text
                            weight="bold"
                            label="Verification code"
                            className="mb-2"
                          />
                          <Text
                            label={`We have sent 6 digit code to ${newEmail}. Check your spam folder and spelling of your email.`}
                            color="muted"
                            className="mb-10"
                          />
                          <OTPView
                            value={oneTimePassword}
                            onChange={(value) => handleOTP(value)}
                            numInputs={6}
                            containerStyle="flex items-center justify-between gap-4 text-black"
                            inputStyle="p-3 border border-gray-300 !w-full h-14 w-14 text-base rounded-md !text-black"
                            focusStyle="focus:border-accent focus:outline-none"
                            shouldAutoFocus
                            isInputNum
                          />

                          <div className="text-sm opacity-60 mt-[48px]">
                            Code hasn't arrived?{" "}
                            <span
                              className="text-skin-primary cursor-pointer"
                              onClick={updateEmailHandler}
                            >
                              Retry
                            </span>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </Modal>
                <Modal
                  backdropClassName="z-1002"
                  className="z-1004"
                  size="regular"
                  showClose={true}
                  showModal={showPasswordModal}
                  onClose={hideModalHandler}
                >
                  <PasswordModal
                    onClose={hideModalHandler}
                    token={getCookie("orgToken")}
                    type={data.getUser.resetPasswordDate ? "Change" : "Set"}
                  />
                </Modal>
                <div className="bg-skin-base relative">
                  <img src={ProfileCover} alt="Profile Cover" />
                  <div className="absolute top-10 left-10 h-24 w-24 border-white border-4 rounded-full profile-cover">
                    <AvatarUpload
                      refetchProfile={refetch}
                      ref={profile}
                      url={data.getUser.photoUrl}
                      userId={data.getUser.id}
                      label={data.getUser.firstName.substring(0, 1)}
                    />
                  </div>
                  <div className="px-10 pb-10 mt-16 flex flex-col gap-y-1 relative">
                    <div className="absolute -top-10 right-10">
                      <Popover
                        onClose={() => setIsProfilePopupOpen(false)}
                        ref={profilePopRef}
                        trigger
                        preferPlace="below"
                        popup={
                          <div className="flex flex-col w-80 p-6 gap-y-8">
                            <Input
                              autoFocus
                              name="firstName"
                              onChange={onChangeHandler}
                              value={payload.firstName}
                              label="First Name"
                            />
                            <Input
                              name="lastName"
                              onChange={onChangeHandler}
                              value={payload.lastName}
                              label="Last Name"
                            />
                            <Input
                              name="workProfile"
                              onChange={onChangeHandler}
                              value={payload.workProfile}
                              label="Job Title"
                            />
                            <Input
                              className="profile-editor-dob"
                              type="date"
                              name="dob"
                              max={new Date().toISOString().split("T")[0]}
                              onChange={onChangeHandler}
                              value={payload.dob}
                              label="Date Of Birth"
                            />
                            <div class="relative min-w-100 w-full">
                              <div class="inline-flex place-items-center text-sm font-normal text-skin-contrast opacity-60 space-x-2 mb-1">
                                <span class="">Location</span>
                              </div>
                              <div class="relative flex items-center border transition-colors space-x-2 rounded px-3 py-2 hover:border-skin-base-300 border-skin-base-200 bg-skin-background w-full">
                                <Autosuggest
                                  renderSuggestion={(suggestion) => (
                                    <div className="p-2 truncate cursor-pointer hover:bg-skin-base-100">
                                      {suggestion}
                                    </div>
                                  )}
                                  getSuggestionValue={(suggestion) =>
                                    suggestion
                                  }
                                  onSuggestionsFetchRequested={
                                    suggestionFetcher
                                  }
                                  onSuggestionsClearRequested={() => {
                                    setLocationSuggestion([]);
                                  }}
                                  suggestions={locationSuggestion || []}
                                  inputProps={{
                                    name: "location",
                                    placeholder: "Eg. Mumbai, India",
                                    value: payload.location,
                                    onChange: (event, { newValue }) => {
                                      setPayload({
                                        ...payload,
                                        location: newValue,
                                      });
                                    },
                                    className:
                                      "flex-1 focus:outline-none text-skin-contrast bg-transparent transition-colors text-base w-full",
                                  }}
                                />
                              </div>
                            </div>

                            <Button
                              disabled={
                                !payload.firstName ||
                                !payload.lastName ||
                                !payload.location ||
                                !payload.workProfile ||
                                !payload.dob ||
                                new Date(payload.dob) > new Date()
                              }
                              loading={profileUpdateLoading}
                              onClick={submitProfile}
                              label="Save"
                              shape="rounded"
                            />
                          </div>
                        }
                      >
                        <Button
                          onClick={() => {
                            if (!isProfilePopupOpen) {
                              setIsProfilePopupOpen(true);
                            } else {
                              setIsProfilePopupOpen(false);
                            }
                          }}
                          accent="contrast"
                          appearance="muted"
                          shape="rounded"
                          className="min-w-[96px]"
                          label="Edit"
                        />
                      </Popover>
                    </div>
                    <div className="flex flex-col mb-4">
                      <Text
                        weight="bold"
                        size="2xl"
                        iconPlacement="right"
                        iconSpacing="close"
                        className="group"
                        label={
                          data.getUser.firstName + " " + data.getUser.lastName
                        }
                      />
                      {data.getUser.workProfile && (
                        <Text
                          label={data.getUser.workProfile}
                          size="sm"
                          iconPlacement="right"
                          iconSpacing="close"
                          className="text-[#616168] group"
                        />
                      )}
                    </div>
                    {data.getUser.location && (
                      <Text
                        label={data.getUser.location}
                        size="sm"
                        icon={IconLocation}
                        className="text-[#616168] opacity-90 group"
                      />
                    )}
                    {data.getUser.dob && (
                      <Text
                        label={moment(data.getUser.dob).format("Do MMM, YYYY")}
                        size="sm"
                        icon={IconDOB}
                        className="text-[#616168] opacity-90 group"
                      />
                    )}
                  </div>
                </div>
                <div className="bg-skin-base mt-[8px]">
                  <div className="px-[40px] pb-[40px]">
                    <Text
                      size="lg"
                      label="Contact Information"
                      weight="bold"
                      className="mt-10"
                    />
                    <hr className="bg-gray-200 my-6" />

                    <div className="flex justify-between items-center group">
                      <div className="flex flex-col">
                        <Text
                          weight="medium"
                          color="muted"
                          size="sm"
                          label="Primary Email ID"
                        />
                        <Text label={data.getUser.email} />
                      </div>
                      <Button
                        shape="rounded"
                        accent="contrast"
                        appearance="muted"
                        label="Change"
                        className="min-w-[96px] group-hover:opacity-100 opacity-0"
                        onClick={() => {
                          setEmailFormPhase(1);
                          setNewEmail("");
                          setShowOtp(true);
                        }}
                      />
                    </div>
                    <hr className="bg-gray-200 my-6" />
                    <div className="flex items-center justify-between group">
                      <div className="flex flex-col">
                        <Text
                          weight="medium"
                          color="muted"
                          size="sm"
                          label="Phone Number"
                        />
                        {data.getUser.phoneNumber ? (
                          <Text size="sm" label={data.getUser.phoneNumber} />
                        ) : (
                          <Text color="muted" size="sm" label="—" />
                        )}
                      </div>
                      <Popover
                        onClose={() => setIsPhonePopupOpen(false)}
                        ref={phonePopRef}
                        trigger
                        preferPlace="left"
                        popup={
                          <div className="flex flex-col p-6 gap-y-6">
                            <div class="relative min-w-100 w-full">
                              <div class="inline-flex place-items-center text-sm font-normal text-skin-contrast opacity-60 space-x-2 mb-1">
                                <span class="">Phone</span>
                              </div>
                              <PhoneInput
                                inputProps={{
                                  autoFocus: true,
                                }}
                                value={payload.phoneNumber}
                                inputClass="relative flex items-center border transition-colors space-x-2 rounded px-3 py-2 hover:border-skin-base-300 border-skin-base-200 bg-skin-background mb-[32px]"
                                onChange={(value) => {
                                  setPayload({
                                    ...payload,
                                    phoneNumber: value,
                                  });
                                }}
                              />
                            </div>
                            <Button
                              disabled={
                                payload.phoneNumber.length < 7 ||
                                payload.phoneNumber.length > 15
                              }
                              loading={profileUpdateLoading}
                              onClick={submitPhone}
                              label="Save"
                              shape="rounded"
                            />
                          </div>
                        }
                      >
                        <Button
                          onClick={() => {
                            setIsPhonePopupOpen(!isPhonePopupOpen);
                          }}
                          shape="rounded"
                          label={data.getUser.phoneNumber ? "Change" : "Add"}
                          className={classNames(
                            "min-w-[96px] opacity-0 group-hover:opacity-100",
                            {
                              "opacity-100": isPhonePopupOpen,
                            }
                          )}
                          appearance="muted"
                          accent="contrast"
                        />
                      </Popover>
                    </div>
                    <hr className="bg-gray-200 my-6" />
                  </div>
                </div>

                <div className="bg-skin-base mt-2">
                  <div className="px-10 pb-10">
                    <Text
                      label="Password & security"
                      weight="bold"
                      className="mt-10 mb-2"
                    />
                    <Text
                      size="sm"
                      color="muted"
                      label="To change your password, verify your current password, then create a new password that you don’t use elsewhere. Change your password anytime you think it might have been compromised."
                    />
                    <hr className="bg-gray-200 my-6" />

                    <div className="flex items-center justify-between">
                      <div className="flex flex-col justify-center">
                        <Text
                          size="sm"
                          color="muted"
                          label="Current Password"
                        />
                        {data.getUser.resetPasswordDate ? (
                          <Text
                            size="sm"
                            className="tracking-widest"
                            label="●●●●●●●●"
                          />
                        ) : (
                          <Text size="sm" label="—" />
                        )}
                      </div>

                      <Button
                        onClick={showModalHandler}
                        appearance="muted"
                        shape="rounded"
                        accent="contrast"
                        className="min-w-[96px]"
                        label={
                          data.getUser.resetPasswordDate ? "Change" : "Set"
                        }
                      />
                    </div>
                    <hr className="divider-opacity dark:divider-opacity-dark my-[24px]" />
                  </div>
                </div>
                <hr className="h-2 border-none bg-[#f2f2f2]" />
                <div className="bg-skin-base">
                  <div className="px-10 pb-10">
                    <div className="flex flex-col">
                      <Text
                        label="Appearance"
                        weight="bold"
                        className="mt-10 mb-2"
                      />
                      <Text
                        size="sm"
                        color="muted"
                        label="Personalize your Samespace with colors to match your style, mood, and personality."
                      />
                    </div>
                    <hr className="bg-gray-200 my-6" />

                    <div className="flex items-center justify-between pb-[32px]">
                      <div className="h-[40px] flex items-center justify-between w-full">
                        {ENTITY_COLORS_APP.map((item) => (
                          <div
                            onClick={() => {
                              setPayload({
                                ...payload,
                                appTheme: item,
                              });
                              submitTheme(item);
                            }}
                            className="flex items-center justify-center cursor-pointer"
                            style={{
                              boxShadow:
                                item === payload?.appTheme
                                  ? `0px 0px 0px 1px #FFFFFF, 0px 0px 0px 2px ${item}`
                                  : null,
                              borderRadius: "50px",
                              background: item,
                              width: "40px",
                              height: "40px",
                            }}
                          >
                            <div className="scale-125">
                              {payload?.appTheme === item && IconColorChecked}
                            </div>
                          </div>
                        ))}
                        <Popover
                          onClose={() => setIsColorPopupOpen(false)}
                          ref={colorPopRef}
                          trigger
                          preferPlace="above"
                          popup={
                            <div className="p-2">
                              <div className="flex items-center">
                                <Input
                                  autoFocus
                                  placeholder="# Hex Code"
                                  value={payload.appTheme}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 7) {
                                      setPayload({
                                        ...payload,
                                        appTheme: e.target.value,
                                      });
                                    }
                                  }}
                                  suffix={
                                    hexColorRegex.test(payload.appTheme) ? (
                                      <div
                                        className="flex items-center justify-center"
                                        onClick={submitTheme.bind(
                                          null,
                                          payload.appTheme
                                        )}
                                      >
                                        <div className="rounded-full bg-skin-primary h-4 w-4 flex items-center justify-center cursor-pointer">
                                          {IconColorChecked}
                                        </div>
                                      </div>
                                    ) : null
                                  }
                                />
                              </div>
                            </div>
                          }
                        >
                          <div
                            onClick={() => {
                              if (!isColorPopupOpen) {
                                setIsColorPopupOpen(true);
                              } else {
                                setIsColorPopupOpen(false);
                              }
                            }}
                            className="h-10 w-10 rounded-full cursor-pointer multicolor-input flex items-center justify-center text-white"
                          >
                            {ENTITY_COLORS_APP.includes(payload.appTheme) &&
                            payload.appTheme
                              ? "#"
                              : IconColorChecked}
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        }
      />
    </>
  );
};

export default ProfileEditor;
