import React from "react";

import { Route, Switch } from "react-router-dom";
import { Toast } from "samespace-zen";
import { useSession } from "./context/session";

import { Header } from "./components/Header";
import Authenticator from "./components/Authenticator";
import { PrivatePage } from "./pages/PrivatePage";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Invite from "./pages/Invite";
import WorkspaceAppLogin from "./components/WorkspaceAppLogin";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import { Product } from "./pages/Product";
import { DISCOVER_ID, products, CONNECT_ID, WORK_ID } from "./utils/constants";
import { LoaderWrapper } from "./components";

import "./assets/styles.css";
import PageNotFound from "./components/PageNotFound";
import RestrictedAccess from "./components/RestrictedAccess";
import SFDockLoading from "./components/SFDockLoading";
import Setup from "./pages/Work/setup";

const Workspace = React.lazy(() => import("./pages/Work"));
const Connect = React.lazy(() => import("./pages/Connect"));
const Discover = React.lazy(() => import("./pages/Discover"));
const Admin = React.lazy(() => import("./pages/Admin"));
const Stories = React.lazy(() => import("./pages/Stories"));

function App({ history }) {
  const [session] = useSession();
  const { loaded, authenticated, activeSpace } = session;

  return (
    <main>
      {authenticated &&
        !window.location.pathname.startsWith("/admin") &&
        !window.location.pathname.includes("/setup") && <Header />}
      {!loaded ? (
        <LoaderWrapper />
      ) : (
        <React.Suspense fallback={<LoaderWrapper />}>
          <Switch>
            {authenticated && (
              <Route path="/access/restricted">
                <RestrictedAccess />
              </Route>
            )}
            <Route
              path="/login"
              children={<Login authenticated={authenticated} />}
            />
            <Route exact path="/work/setup/:section?" children={<Setup />} />
            <Route path="/sf_dock/loading" children={<SFDockLoading />} />
            <Route path="/invite/:token" children={<Invite />} />
            <Route path="/sso/authorize" children={<Authenticator />} />
            <Route path="/oauth/redirect" children={<Authenticator />} />
            <Route path="/add" children={<Authenticator />} />
            <Route
              exact
              path="/workspace-app-login"
              children={<WorkspaceAppLogin />}
            />
            <Route
              exact
              path="/"
              children={
                <PrivatePage>
                  <Home />
                </PrivatePage>
              }
            />
            <Route
              path="/admin"
              children={
                <PrivatePage>
                  <Admin />
                </PrivatePage>
              }
            />

            <Route
              path="/stories"
              children={
                <PrivatePage>
                  <Stories />
                </PrivatePage>
              }
            />

            {products.map((product) => (
              <Route
                // key={"" + activeSpace}
                path={`/${product.path}`}
                children={
                  <PrivatePage>
                    <Product id={product.id} key={activeSpace}>
                      {getProduct(product.id, history, activeSpace)}
                    </Product>
                  </PrivatePage>
                }
              />
            ))}
            {authenticated && (
              <Route
                path="*"
                children={<PageNotFound auth={authenticated} />}
              />
            )}
            {!authenticated && (
              <>
                <Route path="/forgot-password" children={<ForgotPassword />} />
                <Route
                  path="/reset-password/:token"
                  children={<ResetPassword />}
                />
              </>
            )}
          </Switch>
        </React.Suspense>
      )}
      <Toast />
    </main>
  );
}

export default App;

function getProduct(id, history, key) {
  console.log("getProduct key: ", key);
  switch (id) {
    case WORK_ID:
      return <Workspace history={history} key={"" + key} />;
    case CONNECT_ID:
      return <Connect history={history} key={"" + key} />;
    case DISCOVER_ID:
      return <Discover history={history} key={"" + key} />;
    default:
      return null;
  }
}
