import { Text, Input, Button } from "samespace-zen";

import { useHistory } from "react-router-dom";

import { useState, useCallback } from "react";
import { CREATE_PROJECT, CREATE_TEAM } from "./queries";

import { getActiveOrgUrl, getSpaceToken } from "../../../utils/common";

import axios from "axios";
import { print } from "graphql";

const CreateEntity = ({ entity }) => {
  const createTeam = useCallback(({ variables }) => {
    return axios.post(
      `${getActiveOrgUrl()}work/api`,
      {
        query: print(CREATE_TEAM),
        variables: variables,
      },
      {
        headers: {
          Authorization: getSpaceToken(),
        },
      }
    );
  }, []);
  const createProject = useCallback(({ variables }) => {
    return axios.post(
      `${getActiveOrgUrl()}work/api`,
      {
        query: print(CREATE_PROJECT),
        variables: variables,
      },
      {
        headers: {
          Authorization: getSpaceToken(),
        },
      }
    );
  }, []);
  const history = useHistory();
  const [btnStates, setBtnStates] = useState({
    failure: false,
    success: false,
    pending: false,
  });
  const [payload, setPayload] = useState({
    name: "",
  });
  const onChangeHandler = useCallback((e) => {
    setPayload((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const submitFormHandler = useCallback(() => {
    setBtnStates((b) => {
      return {
        ...b,
        pending: true,
      };
    });
    if (entity === "project") {
      createProject({
        variables: {
          project: {
            name: payload.name,
          },
        },
      })
        .then(({ data }) => {
          setBtnStates((b) => {
            return {
              ...b,
              success: true,
              pending: false,
            };
          });
          setTimeout(() => {
            history.push("/work/setup/invite");
          }, 1300);
        })
        .catch((e) => {
          setBtnStates((b) => {
            return {
              ...b,
              pending: false,
              failure: true,
            };
          });
          setTimeout(() => {
            setBtnStates((b) => {
              return {
                ...b,
                pending: false,
                failure: false,
              };
            });
          }, 2000);
        });
    } else {
      createTeam({
        variables: {
          team: {
            name: payload.name,
            trackAppsAndUrl: false,
          },
        },
      })
        .then(({ data }) => {
          setBtnStates((b) => {
            return {
              ...b,
              success: true,
              pending: false,
            };
          });
          setTimeout(() => {
            history.push("/work/setup/project");
          }, 1300);
        })
        .catch((e) => {
          setBtnStates((b) => {
            return {
              ...b,
              pending: false,
              failure: true,
            };
          });
          setTimeout(() => {
            setBtnStates((b) => {
              return {
                ...b,
                pending: false,
                failure: false,
              };
            });
          }, 2000);
        });
    }
  }, [createProject, payload, history, entity, createTeam]);
  const redirectHandler = useCallback(() => {
    if (entity === "team") {
      history.push("/work/setup/project");
    } else {
      history.push("/work/setup/invite");
    }
  }, [history, entity]);

  return (
    <>
      <Text
        label={`Add your ${entity}`}
        weight="semibold"
        className="!text-[32px] text-[#333333]"
      />
      <form
        className="w-full flex flex-col gap-y-8"
        onSubmit={(e) => {
          e.preventDefault();
          submitFormHandler();
        }}
      >
        <Input
          size="medium"
          autoFocus
          name="name"
          value={payload.name}
          onChange={onChangeHandler}
        />

        <div className="w-full flex flex-col gap-[12px]">
          <Button
            size="medium"
            loading={btnStates.pending}
            success={btnStates.success}
            failure={btnStates.failure}
            type="submit"
            disabled={payload.name.length < 3}
            label="Continue"
            full
          />
          <div className="onboarding-secondary-btn">
            <Button
              type="button"
              size="medium"
              accent="contrast"
              full
              appearance="ghost"
              label="I will do it later"
              onClick={redirectHandler}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateEntity;
