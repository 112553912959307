import { products } from "./constants";

import { setCookie } from "./cookies";
import { getSearchParams } from "./location";

export const setAuthenticationData = (data, extractor) => {
  if (data && extractor) {
    const agent = getSearchParams("agent"); // 'dock' or 'work'
    const sessionId = data[extractor].sessionId || getSearchParams("session");
    const user = data[extractor].user;
    const accounts = data[extractor].accounts;
    setCookie("sessionId", sessionId);
    setCookie("user", JSON.stringify(user));
    const organizations = {};
    accounts.forEach((acc) => {
      organizations[acc.orgId] = acc;
    });
    setCookie("organizations", JSON.stringify(organizations));
    let activeOrg;
    let activeSpaceObj;
    if (getSearchParams("orgId")) {
      organizations[getSearchParams("orgId")]
        ? (activeOrg = getSearchParams("orgId"))
        : (activeOrg = Object.keys(organizations)[0]);
    } else {
      if (["work", "dock", "sf_dock"].includes(agent)) {
        activeOrg = Object.keys(organizations).find((item) =>
          organizations[item].spaces?.find(
            (space) =>
              space.productName ===
              (agent === "dock" || agent === "sf_dock"
                ? "Samespace"
                : "Workspace")
          )
        );
        if (!activeOrg) {
          activeOrg = Object.keys(organizations)[0];
        }
      } else {
        activeOrg = Object.keys(organizations)[0];
      }
    }
    setCookie("activeOrg", activeOrg);
    if (getSearchParams("spaceId") && organizations[activeOrg].spaces) {
      const activeSpaceIndex = organizations[activeOrg].spaces.findIndex(
        (space) => space.spaceId === getSearchParams("spaceId")
      );
      if (activeSpaceIndex > -1) {
        activeSpaceObj = organizations[activeOrg].spaces[activeSpaceIndex];
      } else {
        activeSpaceObj = organizations[activeOrg].spaces[0] || {};
      }
    } else {
      if (["work", "dock"].includes(agent)) {
        activeSpaceObj = organizations[activeOrg].spaces?.find(
          (space) =>
            space.productName ===
            (agent === "dock" || agent === "sf_dock"
              ? "Samespace"
              : "Workspace")
        );
        if (!activeSpaceObj) {
          activeSpaceObj =
            organizations[activeOrg].spaces &&
            organizations[activeOrg].spaces.length > 0
              ? organizations[activeOrg].spaces[0]
              : {};
        }
      } else {
        activeSpaceObj =
          organizations[activeOrg].spaces &&
          organizations[activeOrg].spaces.length > 0
            ? organizations[activeOrg].spaces[0]
            : {};
      }
    }
    const productName = activeSpaceObj.productName;
    const activeSpace = activeSpaceObj.spaceId;

    if (activeSpace) {
      setCookie("activeSpace", activeSpace);
    }
    if (agent === "sf_dock") {
      return (window.location.href = "/sf_dock/loading");
    }
    const product = products.find((product) => product.id === productName);
    if (product) {
      window.location.href = `/${product.path}${
        window.location.search ? window.location.search : ""
      }`;
    } else {
      window.location.href = "/";
    }
  } else return;
};
