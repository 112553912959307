import { AuthWrapper } from "../../components/AuthWrapper";
import LoginForm from "./LoginForm";
import { useEffect } from "react";

import { toast } from "samespace-zen";
import { LoaderWrapper } from "../../components";

const Login = ({ authenticated }) => {
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const error = query.get("error");
    const agent = query.get("agent");
    if (error) {
      const message = JSON.parse(atob(error)).message;
      toast.error(message);
    }
    if (agent === "sf_dock" && authenticated) {
      window.location.href = "/sf_dock/loading";
    }
  }, [authenticated]);
  return (
    <>
      {!authenticated ? (
        <AuthWrapper body={<LoginForm />} />
      ) : (
        <LoaderWrapper />
      )}
    </>
  );
};

export default Login;
