import { useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";

import { PasswordRule } from "./PasswordRule";
import { Input, Button, Text, toast } from "samespace-zen";
import { AuthWrapper } from "../../components";
import { IconSamespace } from "../../utils/icons";

import { RESET_PASSWORD } from "./queries";
import { useMutation } from "@apollo/client";

import { useHistory } from "react-router-dom";

import { motion } from "framer-motion";
import { SPECIAL_CHARACTER_REGEX } from "../../utils/constants";

const ResetPasswordForm = () => {
  const params = useParams();
  const history = useHistory();
  const [resetPassword] = useMutation(RESET_PASSWORD, {
    context: {
      headers: {
        Authorization: params.token,
      },
    },
  });
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    onSubmit: (values) => {
      setBtnStates({
        ...btnStates,
        loading: true,
      });
      resetPassword({
        variables: {
          password: values.password,
        },
      })
        .then(() => {
          toast.success("Password Reset Successfully");
          setBtnStates({
            ...btnStates,
            success: true,
            loading: false,
          });
          setTimeout(() => {
            history.push("/login");
          }, 1300);
        })
        .catch((e) => {
          toast.error(e.message || "Something Went Wrong");
          setBtnStates({
            ...btnStates,
            failure: true,
            loading: false,
          });
          setTimeout(() => {
            setBtnStates({
              ...btnStates,
              failure: false,
            });
          }, 1300);
        });
    },
  });
  const [btnStates, setBtnStates] = useState({
    loading: false,
    success: false,
    failure: false,
  });
  const [containsUppercaseLetter, setContainsUppercaseLetter] = useState(false);
  const [containsLowercaseLetter, setContainsLowercaseLetter] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [containsMinRequiredCharacters, setContainsMinRequiredCharacters] =
    useState(false);
  const [containsSpecialCharacters, setContainsSpecialCharacter] =
    useState(false);
  const passwordRules = [
    ["At least one uppercase letter", containsUppercaseLetter],
    ["At least one lowercase letter", containsLowercaseLetter],
    ["At least one number", containsNumber],
    ["8-30 characters", containsMinRequiredCharacters],
    ["At least one special character", containsSpecialCharacters],
  ];

  const validatePassword = () => {
    if (formik.values.password.toLowerCase() !== formik.values.password)
      setContainsUppercaseLetter(true);
    else setContainsUppercaseLetter(false);

    if (formik.values.password.toUpperCase() !== formik.values.password)
      setContainsLowercaseLetter(true);
    else setContainsLowercaseLetter(false);

    if (/\d/.test(formik.values.password)) setContainsNumber(true);
    else setContainsNumber(false);

    if (
      formik.values.password.length >= 8 &&
      formik.values.password.length <= 30
    )
      setContainsMinRequiredCharacters(true);
    else setContainsMinRequiredCharacters(false);

    if (SPECIAL_CHARACTER_REGEX.test(formik.values.password))
      setContainsSpecialCharacter(true);
    else setContainsSpecialCharacter(false);
  };

  return (
    <>
      <motion.div
        layout
        key="forgot-form"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{
          opacity: 0,
        }}
        className="flex flex-col w-[500px] text-left gap-y-8 justify-center"
      >
        <div className="w-full flex flex-col items-center justify-center">
          <div className="mb-[32px]">{IconSamespace}</div>
          <Text size="xl" weight="medium" label="Reset Password" />
        </div>

        <Input
          autoFocus
          inputClassName="font-serif"
          label="Password"
          name="password"
          type="password"
          onChange={(e) =>
            formik.setFieldValue("password", e.target.value.trim())
          }
          value={formik.values.password}
          onKeyUp={validatePassword}
        />

        <div className="flex flex-col gap-2">
          {passwordRules.map((rule, index) => {
            return <PasswordRule rule={rule} key={index} />;
          })}
        </div>

        <Button
          disabled={
            !(
              containsUppercaseLetter &&
              containsLowercaseLetter &&
              containsNumber &&
              containsMinRequiredCharacters &&
              containsSpecialCharacters
            )
          }
          full
          success={btnStates.success}
          loading={btnStates.loading}
          failure={btnStates.failure}
          shape="rounded"
          onClick={() => formik.submitForm()}
          label="Reset Password"
        />
      </motion.div>
    </>
  );
};

const ResetPassword = () => {
  return <AuthWrapper body={<ResetPasswordForm />} />;
};

export default ResetPassword;
