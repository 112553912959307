import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  useParams,
} from "react-router-dom";

import Header from "./Header";
import Profile from "./profile";
import TypeSelection from "./typeselection";
import CompanyForm from "./companyform";
import CreateEntity from "./create-entity";
import InvitePeople from "./invitepeople";
import DownloadApp from "./downloadapp";

import { AnimatePresence } from "framer-motion";

import SwipeAnimationPage from "../../../components/SwipeAnimationPage";
import SlideAnimationPage from "../../../components/SlideAnimationPage";

import DotSteps from "../../../components/DotSteps";

import { Loader } from "samespace-zen";

import { gql } from "@apollo/client";

import { useQuery } from "@apollo/client";
import { getSearchParams } from "../../../utils/location";
import { setCookie } from "../../../utils/cookies";
const GET_SESSION = gql`
  query getSession($getSessionId: String!, $orgId: ID, $spaceId: Int) {
    getSession(id: $getSessionId, orgId: $orgId, spaceId: $spaceId) {
      type
      value
      version
    }
  }
`;

const Setup = () => {
  const history = useHistory();
  const params = useParams();

  const { data, loading } = useQuery(GET_SESSION, {
    skip: params.section,
    variables: {
      getSessionId: getSearchParams("sessionId"),
    },
  });
  const location = useLocation();
  const [invitePayload, setInvitePayload] = useState({
    team: "",
    project: "",
  });

  useEffect(() => {
    if (data && data.getSession) {
      const user = data.getSession.find((item) => item.type === "user");
      if (user) {
        setCookie("userToken", user.value);
        history.push("/work/setup/profile");
      }
    }
  }, [data, history]);
  return (
    <>
      {loading ? (
        <div className="h-screen w-screen flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <Header />
          {console.log(params, "PARAMS")}
          <div
            className={`onboarding-container flex justify-center ${
              window.location.pathname.includes("setup/profile") ||
              window.location.pathname.includes("setup/typeselect")
                ? "items-center"
                : ""
            } `}
          >
            <AnimatePresence exitBeforeEnter>
              <Switch location={location} key={location.pathname}>
                <Route exact path="/work/setup/profile">
                  <SlideAnimationPage>
                    <Profile />
                  </SlideAnimationPage>
                </Route>
                <Route exact path="/work/setup/typeselect">
                  <SlideAnimationPage>
                    <TypeSelection />
                  </SlideAnimationPage>
                </Route>

                <Route exact path="/work/setup/company">
                  <div className="flex flex-col justify-start items-center">
                    <DotSteps step={1} />
                    <SwipeAnimationPage noInitial>
                      <CompanyForm />
                    </SwipeAnimationPage>
                  </div>
                </Route>
                <Route exact path="/work/setup/team">
                  <div className="flex flex-col justify-start items-center">
                    <DotSteps step={2} />
                    <SwipeAnimationPage>
                      <CreateEntity
                        entity="team"
                        inviteData={invitePayload}
                        setInviteData={setInvitePayload}
                      />
                    </SwipeAnimationPage>
                  </div>
                </Route>
                <Route exact path="/work/setup/project">
                  <div className="flex flex-col justify-start items-center">
                    <DotSteps step={3} />
                    <SwipeAnimationPage>
                      <CreateEntity
                        entity="project"
                        inviteData={invitePayload}
                        setInviteData={setInvitePayload}
                      />
                    </SwipeAnimationPage>
                  </div>
                </Route>
                <Route exact path="/work/setup/invite">
                  <div className="flex flex-col justify-start items-center">
                    <DotSteps step={4} />
                    <SwipeAnimationPage>
                      <InvitePeople misc={invitePayload} />
                    </SwipeAnimationPage>
                  </div>
                </Route>
                <Route exact path="/work/setup/download">
                  <div className="flex flex-col justify-start items-center">
                    <DotSteps step={5} />
                    <SwipeAnimationPage>
                      <DownloadApp />
                    </SwipeAnimationPage>
                  </div>
                </Route>
              </Switch>
            </AnimatePresence>
          </div>
        </>
      )}
    </>
  );
};

export default Setup;
