import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useMutation, useQuery } from "@apollo/client";

import { IconGoogle, IconRightArrow } from "../../utils/icons";

import { Loader, Avatar, Button, toast } from "samespace-zen";

import { randomColorGenerator } from "../../utils/common";

import { CHECK_INVITED_USER, COMPLETE_INVITE } from "./queries";

import UserSetupForm from "./UserSetupForm";
import Header from "./Header";

import jwt_decode from "jwt-decode";

import { Text } from "samespace-zen";

import { motion, AnimatePresence } from "framer-motion";

import { SIGN_IN_WITH_OAUTH } from "../Login/queries";
import { setAuthenticationData } from "../../utils/auth";

import Platform from "platform";
import { uuidv4 } from "../../utils/common";

const Invite = () => {
  const [step, setStep] = useState(false);
  const [inviteId, setInviteId] = useState("");

  const [signInWithOAuth] = useMutation(SIGN_IN_WITH_OAUTH);
  const [orgName, setOrgName] = useState("");
  const params = useParams();
  const [orgId, setOrgId] = useState("");
  const [invitePayload, setInvitePayload] = useState();
  const [completeInvite] = useMutation(COMPLETE_INVITE, {
    context: {
      headers: {
        Authorization: `${params.token}`,
      },
    },
  });
  const { data, loading, error } = useQuery(CHECK_INVITED_USER, {
    context: {
      headers: {
        Authorization: `${params.token}`,
      },
    },
  });
  useEffect(() => {
    setInviteId(jwt_decode(params.token).inviteId);
    setOrgName(jwt_decode(params.token).orgName);
    setOrgId(jwt_decode(params.token).orgId);
    if (!data?.checkInvitedUser?.email) {
      setStep(1);
      setInvitePayload({
        checkInvitedUser: {
          email: jwt_decode(params.token).email,
        },
      });
    } else {
      setInvitePayload(data);
      setStep(false);
    }
  }, [params.token, data]);
  const stepHandler = useCallback((step) => {
    setStep(step);
  }, []);
  const directLoginHandler = useCallback(() => {
    completeInvite()
      .then(({ data }) => {
        if (data.completeInvite.success) {
          setAuthenticationData(data.completeInvite, "success");
        } else {
          data.completeInvite?.failure?.map((item) => {
            return toast.error(`Adding to Space ${item.entity} failed`);
          });
        }
      })
      .catch((e) => {
        toast.error(e.message || "Something Went Wrong!");
      });
  }, [completeInvite]);
  if (error) {
    return (
      <div className="h-screen w-screen flex items-center justify-center flex-col fixed z-[10004] top-0 left-0">
        <img
          src={require("../../assets/images/boat.png")}
          alt="Sinking Boat| Samespace"
        />
        <Text
          className="mt-4"
          size="lg"
          weight="bold"
          label="Invite Link Expired"
        />
        <Text
          className="text-center mt-2"
          size="base"
          color="muted"
          label="Contact your Admin"
        />
      </div>
    );
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {loading && (
        <div className=" w-full h-screen flex items-center justify-center">
          <Loader />
        </div>
      )}
      {!loading && <Header />}

      {!loading && step && (
        <motion.div
          initial={{
            x: 100,
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { duration: 0.4, ease: "easeOut" },
          }}
          exit={{
            x: -100,
            opacity: 0,
            transition: { duration: 0.4, ease: "easeIn" },
          }}
          className="flex items-center justify-center w-full h-full pt-8"
        >
          <div className="w-[500px] mt-20">
            <div className="w-full flex  items-center justify-center">
              <div className="text-[24px] text-[#333333] font-semibold mb-[40px]">
                {`Join ${orgName}`}
              </div>
            </div>
            <UserSetupForm orgId={orgId} invitePayload={invitePayload} />
            <div className="w-full or-divider my-[28px] relative">
              <div className="or-text">OR</div>
            </div>
            <Button
              full
              onClick={() =>
                signInWithOAuth({
                  variables: {
                    payload: {
                      params: window.location.search
                        ? window.location.search.substring(1)
                        : "",
                      device: {
                        id: uuidv4(),
                        name: `${Platform.name},${Platform.os.toString()}`,
                        version: Platform.os.version,
                        appName: "WEB_APP",
                        appVersion: "1",
                      },
                      provider: "GOOGLE",
                      inviteId: inviteId,
                    },
                  },
                }).then(({ data }) => {
                  window.location = data.signInWithOAuth;
                })
              }
              accent="contrast"
              appearance="muted"
              shape="rounded"
              icon={IconGoogle}
              label="Continue With Google"
            />
          </div>
        </motion.div>
      )}

      <div className="flex items-center flex-col justify-center">
        {!loading && data.checkInvitedUser && !step && (
          <motion.div
            initial={{
              x: 100,
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              x: 0,
              transition: { duration: 0.4, ease: "easeOut" },
            }}
            exit={{
              x: -100,
              opacity: 0,
              transition: { duration: 0.4, ease: "easeIn" },
            }}
            className="w-[500px] flex flex-col items-center justify-center pt-20"
          >
            <div className="text-[24px] text-[#333333] font-semibold mb-[40px]">
              {`Join ${orgName}`}
            </div>
            <div className="w-[500px]">
              <div className="text-sm opacity-60 text-left mb-2">
                Continue With
              </div>
              <div className="org-list">
                <div
                  className="org-item flex items-center"
                  onClick={directLoginHandler}
                >
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <Avatar
                        label={data?.checkInvitedUser?.first_name.substring(
                          0,
                          1
                        )}
                        color={randomColorGenerator(
                          Math.floor(Math.random() * 6)
                        )}
                        size="small"
                      />
                      <div className="flex items-start flex-col ml-2">
                        <div className="font-bold flex items-center">
                          {data.checkInvitedUser?.first_name +
                            " " +
                            data?.checkInvitedUser?.last_name}
                        </div>
                        <div className="text-sm opacity-60">
                          {data?.checkInvitedUser?.email}
                        </div>
                      </div>
                    </div>
                    <div className="opacity-60">{IconRightArrow}</div>
                  </div>
                </div>
              </div>
              <div className="w-full or-divider my-[28px] relative">
                <div className="or-text">OR</div>
              </div>
              <div
                className="org-list mt-2"
                onClick={stepHandler.bind(null, 1)}
              >
                <div className="org-item font-medium flex items-center justify-between w-full">
                  <div>Create New Account</div>
                  <div className="opacity-60">{IconRightArrow}</div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </AnimatePresence>
  );
};

export default Invite;
