import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { Text, Button } from "samespace-zen";

import PageNotFoundImg from "../assets/images/page_not_found.png";

const PageNotFound = ({ auth }) => {
  const history = useHistory();
  const onHomePageRedirectHandler = useCallback(() => {
    if (auth) {
      return history.goBack();
    } else {
      return (window.location.href = "/login");
    }
  }, [history, auth]);
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <img className="w-[200px]" src={PageNotFoundImg} alt="Page Not Found" />
      <div className="w-[400px] text-center mt-[8px] mb-[16px]">
        <Text
          label="Sorry we couldn't find the page you were looking for"
          size="lg"
        />
      </div>
      <Button
        className="w-[160px] mt-[16px]"
        shape="rounded"
        label={auth ? "Back" : "Back to Login"}
        onClick={onHomePageRedirectHandler}
      />
    </div>
  );
};

export default PageNotFound;
