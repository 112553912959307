import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { Text, Button } from "samespace-zen";
import { useSession } from "../context/session";
import { products } from "../utils/constants";

function Product({ children, id }) {
  const [session] = useSession();

  const [productInfo, setProductInfo] = useState({});

  useEffect(() => {
    if (!session.spaceToken) {
      const productInfo = products.find((pr) => pr.id === id);
      setProductInfo(productInfo);
    }
  }, [id, session.spaceToken]);

  const { title, description, icon } = productInfo;
  return session.spaceToken ? (
    children
  ) : (
    <div className="w-full h-screen flex items-center justify-center">
      <motion.div
        key={title}
        className="flex flex-col items-center justify-center"
        transition={{ duration: 0.3, ease: "easeInOut" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <Text
          icon={
            <div className="w-[72px] h-[72px] rounded-full bg-skin-base-100 flex items-center justify-center">
              {icon}
            </div>
          }
          label={title}
          iconPlacement="top"
          iconSpacing="normal"
        />
        <Text label={description} className="mt-1" color="muted" />
        <Button label="Try Free" shape="rounded" className="mt-4 w-[120px]" />
      </motion.div>
    </div>
  );
}

export { Product };
