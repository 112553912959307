import { motion } from "framer-motion";

const SwipeAnimationPage = ({ children, noInitial }) => {
  return (
    <motion.div
      initial={
        noInitial
          ? {
              opacity: 0,
            }
          : {
              x: 100,
              opacity: 0,
            }
      }
      animate={
        noInitial
          ? {
              opacity: 1,
              transition: { duration: 0.4, ease: "easeOut" },
            }
          : {
              opacity: 1,
              x: 0,
              transition: { duration: 0.4, ease: "easeOut" },
            }
      }
      exit={{
        x: -100,
        opacity: 0,
        transition: { duration: 0.4, ease: "easeIn" },
      }}
      className="w-[500px] h-full flex flex-col items-center gap-[40px]"
    >
      {children}
    </motion.div>
  );
};

export default SwipeAnimationPage;
