import { useEffect } from "react";

import { IconLoader } from "../utils/icons";

import { useQuery } from "@apollo/client";

import { getSearchParams } from "../utils/location";
import { setAuthenticationData } from "../utils/auth";

import { gql } from "@apollo/client";
const GET_SESSION_DETAILS = gql`
  query getSessionDetails($id: String!) {
    getSessionDetails(id: $id)
  }
`;

const Authenticator = () => {
  const { data } = useQuery(GET_SESSION_DETAILS, {
    variables: {
      id: getSearchParams("session"),
    },
  });

  useEffect(() => {
    if (data) {
      setAuthenticationData(data, "getSessionDetails");
    }
  }, [data]);
  return IconLoader;
};

export default Authenticator;
