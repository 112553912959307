import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  fromPromise,
  createHttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getDomain, logout } from "../utils/common";

import { toast } from "samespace-zen";

import axios from "axios";
import { getCookie } from "../utils/cookies";

import { setAuthenticationData } from "../utils/auth";

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    const code = graphQLErrors[0]?.extensions?.code;
    if (code === "UNAUTHENTICATED") {
      logout();
      toast.error(graphQLErrors[0].message);
    } else if (code === "TOKEN_EXPIRED") {
      return fromPromise(
        axios
          .post(`https://api.${getDomain()}/signin/api`, {
            operationName: "RefreshSession",
            variables: {
              id: getCookie("sessionId"),
            },
            query: `mutation RefreshSession($id: String!) {
                refreshSession(id: $id)
              }`,
          })
          .then(({ data }) => {
            return setAuthenticationData(data.data, "refreshSession");
          })
          .catch((e) => {
            logout();
            toast.error(e.message || "Something went wrong!");
          })
      );
    } else if (code === "FORBIDDEN") {
      toast.error("You don't have sufficient privileges to access this page.");
      throw new Error("Insufficient Privileges");
    }
  }
});

export const authClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    errorLink,
    new createHttpLink({
      uri: `https://api.${getDomain()}/signin/api`,
    }),
  ]),
});
