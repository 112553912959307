import { IconRestrictedAccess } from "../utils/icons";

import { Text } from "samespace-zen";

const RestrictedAccess = () => {
  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      <div className="mb-10">{IconRestrictedAccess}</div>
      <div className="flex flex-col justify-center items-center w-1/4 gap-y-2 text-center">
        <Text weight="bold" label="This page is restricted" />
        <Text
          size="sm"
          label="You do not have permission to access this page. If you think this is incorrect, please contact your samespace admin."
        />
      </div>
    </div>
  );
};

export default RestrictedAccess;
