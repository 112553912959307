import React, { useState, useCallback } from "react";

import { useMutation } from "@apollo/client";

import { SET_PASSWORD, CHANGE_PASSWORD } from "./queries";

import {
  ModalTitle,
  ModalBody,
  ModalFooter,
  Input,
  toast,
  Button,
} from "samespace-zen";

import { PasswordRule } from "../../pages/ResetPassword/PasswordRule";

import { SPECIAL_CHARACTER_REGEX } from "../../utils/constants";

const PasswordModal = ({ token, type, onClose }) => {
  const [containsUppercaseLetter, setContainsUppercaseLetter] = useState(false);
  const [containsLowercaseLetter, setContainsLowercaseLetter] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [containsMinRequiredCharacters, setContainsMinRequiredCharacters] =
    useState(false);
  const [containsSpecialCharacters, setContainsSpecialCharacter] =
    useState(false);
  const [btnStates, setBtnStates] = useState({
    pending: false,
    success: false,
    failure: false,
  });
  const passwordRules = [
    ["At least one uppercase letter", containsUppercaseLetter],
    ["At least one lowercase letter", containsLowercaseLetter],
    ["At least one number", containsNumber],
    ["8-30 characters", containsMinRequiredCharacters],
    ["At least one special character", containsSpecialCharacters],
  ];

  const validatePassword = () => {
    if (payload.newPassword.toLowerCase() !== payload.newPassword)
      setContainsUppercaseLetter(true);
    else setContainsUppercaseLetter(false);

    if (payload.newPassword.toUpperCase() !== payload.newPassword)
      setContainsLowercaseLetter(true);
    else setContainsLowercaseLetter(false);

    if (/\d/.test(payload.newPassword)) setContainsNumber(true);
    else setContainsNumber(false);

    if (payload.newPassword.length >= 8 && payload.newPassword.length <= 30)
      setContainsMinRequiredCharacters(true);
    else setContainsMinRequiredCharacters(false);
    if (SPECIAL_CHARACTER_REGEX.test(payload.newPassword))
      setContainsSpecialCharacter(true);
    else setContainsSpecialCharacter(false);
  };
  const [setPassword] = useMutation(SET_PASSWORD, {
    refetchQueries: ["GetUser"],
    context: {
      headers: {
        Authorization: token,
      },
    },
  });
  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    refetchQueries: ["GetUser"],
    context: {
      headers: {
        Authorization: token,
      },
    },
  });
  const [payload, setPayload] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const onChangeHandler = useCallback(
    (e) => {
      setPayload({
        ...payload,
        [e.target.name]: e.target.value.trim(),
      });
    },
    [payload]
  );
  const submitFormHandler = useCallback(async () => {
    setBtnStates({
      ...btnStates,
      pending: true,
    });
    try {
      if (type === "Set") {
        await setPassword({
          variables: {
            newPassword: payload.newPassword,
            confirmPassword: payload.newPassword,
          },
        });
      } else {
        await changePassword({
          variables: {
            oldPassword: payload.oldPassword,
            newPassword: payload.newPassword,
          },
        });
      }
      setBtnStates({
        ...btnStates,
        pending: false,
        success: true,
      });
      setTimeout(() => {
        onClose();
      }, 1300);
    } catch (e) {
      toast.error(e.message || "Something went wrong");
      setBtnStates({
        ...btnStates,
        failure: true,
        pending: false,
      });
      setTimeout(() => {
        setBtnStates({
          ...btnStates,
          failure: false,
        });
      }, 3000);
    }
  }, [type, btnStates, changePassword, payload, setPassword, onClose]);

  return (
    <>
      <ModalTitle label={`${type} Password`} />
      <ModalBody>
        <div className="flex flex-col gap-[24px]">
          {type === "Change" && (
            <Input
              autoFocus
              type="password"
              name="oldPassword"
              value={payload.oldPassword}
              label="Old Password"
              onChange={onChangeHandler}
            />
          )}
          <Input
            autoFocus={type !== "Change"}
            type="password"
            name="newPassword"
            inputClassName="font-serif"
            value={payload.newPassword}
            onChange={onChangeHandler}
            label="New Password"
            onKeyUp={validatePassword}
          />
        </div>
        <div className="grid grid-cols-2 gap-2 mt-4">
          {passwordRules.map((rule, index) => {
            return <PasswordRule rule={rule} key={index} />;
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex w-full justify-end">
          <Button
            onClick={onClose}
            label="Cancel"
            appearance="muted"
            accent="contrast"
            shape="rounded"
          />
          <Button
            loading={btnStates.pending}
            success={btnStates.success}
            failure={btnStates.failure}
            className="ml-[12px]"
            label={type}
            disabled={
              type === "Change"
                ? payload.oldPassword.length < 7 ||
                  !(
                    containsUppercaseLetter &&
                    containsLowercaseLetter &&
                    containsNumber &&
                    containsMinRequiredCharacters &&
                    containsSpecialCharacters
                  )
                : !(
                    containsUppercaseLetter &&
                    containsLowercaseLetter &&
                    containsNumber &&
                    containsMinRequiredCharacters &&
                    containsSpecialCharacters
                  )
            }
            shape="rounded"
            onClick={submitFormHandler}
          />
        </div>
      </ModalFooter>
    </>
  );
};

export default PasswordModal;
