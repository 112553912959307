import { gql } from "@apollo/client";
import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import { GET_USER_PROFILE } from "../components/Header/queries";
import { authClient } from "../graphql/Provider";

import { getSavedSession, hexToRgb } from "../utils/common";
import { products } from "../utils/constants";
import { getCookie, removeCookie, setCookie } from "../utils/cookies";
import { getSearchParams } from "../utils/location";

export const initialSession = {
  loaded: false,
  authenticated: false,
  user: {},
  spaceToken: null,
  activeProduct: null,
  activeSpace: null,
};

const SessionContext = createContext();

export function useSession() {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error(`useSession must be used within a SessionProvider`);
  }
  return context;
}

export function SessionProvider(props) {
  const [session, setSession] = useState(props.value || initialSession);
  const location = useLocation();
  const activeProduct = useRef(null);

  useEffect(() => {
    console.log("useEffect 1: ");
    const savedSession = getSavedSession();

    const theme = localStorage.getItem("THEME");
    if (theme) {
      const body = document.querySelector(`body`);
      body?.style.setProperty("--color-primary", hexToRgb(theme));
      body?.style.setProperty("--primary-rgb", hexToRgb(theme));
      body?.style.setProperty("--primary", theme);
    }
    if (savedSession && savedSession.sessionId) {
      console.log("useEffect 1: savedSession For THEME ", savedSession);
      const organizations = savedSession.organizations;
      const activeOrg = savedSession.activeOrg;
      if (savedSession.orgToken) {
        if (
          (!organizations[activeOrg].spaces ||
            organizations[activeOrg].spaces.length === 0) &&
          window.location.pathname !== "/access/restricted"
        ) {
          window.location.href = "/access/restricted";
        }
      }
      setSession((prevState) => ({
        ...prevState,
        ...savedSession,
        loaded: true,
        authenticated: true,
      }));
    } else {
      setSession((prevState) => ({
        ...prevState,
        loaded: true,
        authenticated: false,
      }));
    }
  }, []);

  useEffect(() => {
    console.log("useEffect 2: ", location.pathname);
    if (location.pathname === "/") {
      activeProduct.current = null;
      return;
    }
    const productPath = location.pathname.split("/")[1];
    if (productPath === activeProduct.current) return;

    const product = products.find((pr) => pr.path === productPath);
    if (product && !window.location.pathname.includes("/setup")) {
      activeProduct.current = productPath;
      const savedSession = getSavedSession();
      if (savedSession) {
        const organizations = savedSession.organizations;
        const activeOrg = savedSession.activeOrg;
        setSession((prevState) => {
          console.log("setSession prevState: ", prevState);
          return {
            ...prevState,
            loaded: false,
            spaceToken: null,
            activeProduct: null,
          };
        });
        removeCookie("spaceToken");
        removeCookie("spaceUserInfo");

        if (
          !organizations[activeOrg].spaces ||
          organizations[activeOrg].spaces.length === 0
        ) {
          const sessionId = getCookie("sessionId");
          authClient
            .query({
              query: GET_SESSION,
              variables: {
                getSessionId: sessionId,
                orgId: activeOrg,
              },
            })
            .then(({ data }) => {
              const responseSession = data.getSession;
              const orgToken =
                responseSession.find((sess) => sess.type === "org") || {};
              if (orgToken.value) {
                setCookie("orgToken", orgToken.value);
              }
              setSession((prevState) => ({
                ...prevState,
                loaded: true,
              }));
              window.location.href = "/access/restricted";
              return;
            });
        }
        const sessionId = getCookie("sessionId");

        const firstSpace = organizations[activeOrg].spaces?.find(
          (sp) => sp.productName === product.id
        );
        const switchedSpace = organizations[activeOrg].spaces
          ?.filter((sp) => sp.productName === product.id)
          .find((sp) => String(sp.spaceId) === getCookie("activeSpace"));
        console.log("switchedSpace & firstSpace: ", switchedSpace, firstSpace);
        const space = +switchedSpace?.spaceId || +firstSpace?.spaceId;
        if (space) {
          removeCookie("activeSpace");
          authClient
            .query({
              query: GET_SESSION,
              variables: {
                getSessionId: sessionId,
                orgId: activeOrg,
                spaceId: space,
              },
            })
            .then((res) => {
              const sessions = res.data.getSession;
              if (sessions && sessions.length > 0) {
                const sso = sessions.find((sess) => sess.type === "sso");
                if (sso) {
                  return (window.location.href = `${sso.value}&${
                    window.location.search
                      ? window.location.search.substring(1)
                      : ""
                  }`);
                }
                const orgToken =
                  sessions.find((sess) => sess.type === "org") || {};
                if (orgToken.value) {
                  setCookie("orgToken", orgToken.value);
                  const theme = localStorage.getItem("THEME");
                  if (!theme) {
                    authClient
                      .query({
                        query: GET_USER_PROFILE,
                        context: {
                          headers: {
                            authorization: orgToken.value,
                          },
                        },
                      })
                      .then(({ data }) => {
                        if (data?.getUser?.appTheme) {
                          localStorage.setItem("THEME", data.getUser.appTheme);
                          const body = document.querySelector(`body`);
                          body?.style.setProperty(
                            "--color-primary",
                            hexToRgb(data.getUser.appTheme)
                          );
                          body?.style.setProperty(
                            "--primary-rgb",
                            hexToRgb(data.getUser.appTheme)
                          );
                          body?.style.setProperty(
                            "--primary",
                            data.getUser.appTheme
                          );
                        }
                      });
                  }
                }
                const spaceToken = sessions.find(
                  (sess) => sess.type === "space"
                );
                if (spaceToken) {
                  setCookie("activeSpace", space);
                  setCookie("spaceToken", spaceToken.value);
                  setCookie("appVersion", spaceToken.version || "");
                  const agent = getSearchParams("agent");
                  if (agent === "work") {
                    const appProtocol =
                      getSearchParams("appProtocol") || "workspace";
                    const url = new URL(`${appProtocol}://desktop-redirect`);
                    url.searchParams.append("sessionId", sessionId);
                    const orgId = getSearchParams("orgId");
                    if (orgId) {
                      url.searchParams.append("orgId", orgId);
                    }
                    window.location = url;
                  }
                  setSession((prevState) => ({
                    ...prevState,
                    spaceToken: spaceToken.value,
                    activeProduct: product.id,
                    activeSpace: space,
                    loaded: true,
                  }));
                } else {
                  setSession((prevState) => ({
                    ...prevState,
                    loaded: true,
                  }));
                }
              } else {
                setSession((prevState) => ({
                  ...prevState,
                  loaded: true,
                }));
              }
            })
            .catch((err) => {
              setSession((prevState) => ({
                ...prevState,
                loaded: true,
              }));
            });
        } else {
          setSession((prevState) => ({
            ...prevState,
            loaded: true,
          }));
        }
      }
    }
  }, [location.pathname]);

  const value = useMemo(() => [session, setSession], [session]);
  return <SessionContext.Provider value={value} {...props} />;
}

const GET_SESSION = gql`
  query getSession($getSessionId: String!, $orgId: ID, $spaceId: Int) {
    getSession(id: $getSessionId, orgId: $orgId, spaceId: $spaceId) {
      type
      value
      version
    }
  }
`;
