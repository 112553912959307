import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import ReactPopover from "react-popover";

const Popover = forwardRef(
  (
    {
      preferPlace,
      popup,
      closeOnOutsideClick,
      children,
      className,
      disabled,
      trigger,
      onClose,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    let showTimeout;
    const show = () => {
      setIsOpen(true);
    };

    const hide = useCallback(() => {
      onClose && onClose();
      setIsOpen(false);
    }, [onClose]);
    useImperativeHandle(ref, () => {
      return {
        show: show,
        hide: hide,
        open: isOpen,
        isOpen,
      };
    });

    useEffect(() => {
      const handleKeyPress = (e) => {
        if (e.keyCode === 27 && isOpen) {
          hide();
        }
      };
      document.addEventListener("keyup", handleKeyPress);
      return () => {
        document.removeEventListener("keyup", handleKeyPress);
      };
    }, [hide, isOpen]);
    return (
      <div className={`date-popover ${trigger && "flex items-center"}`}>
        <ReactPopover
          className={`SwitchPopover ${className}`}
          isOpen={disabled ? false : isOpen}
          preferPlace={preferPlace}
          body={[popup]}
          tipSize={8}
          appendTarget={document.body}
          onOuterAction={() => {
            if (closeOnOutsideClick) {
              onClose && onClose();
              setIsOpen(false);
            }
          }}
        >
          {!trigger ? (
            <span
              className="date-popover__trigger w-full"
              onMouseEnter={() => {
                showTimeout = setTimeout(() => {
                  setIsOpen(true);
                }, 500);
              }}
              onMouseLeave={() => {
                clearTimeout(showTimeout);
                setIsOpen(false);
              }}
            >
              {children}
            </span>
          ) : (
            <span
              className="date-popover__trigger w-full"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              {children}
            </span>
          )}
        </ReactPopover>
      </div>
    );
  }
);

Popover.propTypes = {
  /** placement */
  preferPlace: PropTypes.oneOf(
    "start",
    "end",
    "above",
    "right",
    "below",
    "left",
    "column",
    "row"
  ),
  /** popup content */
  popup: PropTypes.node,
  /** close popup when clicked outside */
  closeOnOutsideClick: PropTypes.bool,
};

Popover.defaultProps = {
  search: [],
  content: "",
  closeOnOutsideClick: true,
};

export default Popover;
