import React from "react";
import { DashIcon, TickMarkIcon } from "./icons";

export const PasswordRule = ({ rule }) => {
  const label = rule[0];
  const satisfied = rule[1];

  return (
    <div className="flex items-center gap-2 text-sm">
      <div className={`${satisfied ? "text-green-700" : "text-black/20"}`}>
        {satisfied ? <TickMarkIcon /> : <DashIcon />}
      </div>
      <div className="text-black/60">{label}</div>
    </div>
  );
};
