import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Loader } from "samespace-zen";
import { routeToActiveProductHome } from "../../utils/common";

const Home = () => {
  const history = useHistory();

  useEffect(() => {
    routeToActiveProductHome(history);
  }, [history]);

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <Loader />
    </div>
  );
};

export default Home;
