import { gql } from "@apollo/client";

export const COMPLETE_INVITE = gql`
  mutation CompleteInvite($payload: CompleteInviteInput) {
    completeInvite(payload: $payload)
  }
`;

export const CHECK_INVITED_USER = gql`
  query checkInvitedUser {
    checkInvitedUser
  }
`;
