import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Input, Button, toast } from "samespace-zen";
import { COMPLETE_INVITE } from "./queries";
import { emailRegex } from "../../utils/common";
import { IconPasswordHide, IconPasswordShow } from "../../utils/icons";
import { setAuthenticationData } from "../../utils/auth";

import { PasswordRule } from "../ResetPassword/PasswordRule";

import { SPECIAL_CHARACTER_REGEX } from "../../utils/constants";

const UserSetupForm = ({ invitePayload, orgId }) => {
  const params = useParams();
  const [type, setType] = useState("password");
  const [containsUppercaseLetter, setContainsUppercaseLetter] = useState(false);
  const [containsLowercaseLetter, setContainsLowercaseLetter] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [containsMinRequiredCharacters, setContainsMinRequiredCharacters] =
    useState(false);
  const [containsSpecialCharacters, setContainsSpecialCharacter] =
    useState(false);
  const [btnStates, setBtnStates] = useState({
    success: false,
    loading: false,
    failed: false,
  });
  const [payload, setPayload] = useState({
    firstName: invitePayload?.checkInvitedUser?.first_name || "",
    lastName: invitePayload?.checkInvitedUser?.last_name || "",
    password: "",
    email: invitePayload?.checkInvitedUser?.email || "",
  });
  const disabledButton = useCallback(() => {
    if (
      payload?.password?.length >= 8 &&
      payload.firstName &&
      payload.lastName &&
      payload.email.match(emailRegex)
    ) {
      return false;
    } else {
      return true;
    }
  }, [payload]);
  const passwordTypeHandler = useCallback(() => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  }, [type]);
  const [completeInvite] = useMutation(COMPLETE_INVITE, {
    context: {
      headers: {
        Authorization: `${params.token}`,
      },
    },
  });
  const onChangeHandler = useCallback(
    (e) => {
      setPayload({
        ...payload,
        [e.target.name]: e.target.value.trim(),
      });
    },
    [payload]
  );

  const passwordRules = [
    ["At least one uppercase letter", containsUppercaseLetter],
    ["At least one lowercase letter", containsLowercaseLetter],
    ["At least one number", containsNumber],
    ["8-30 characters", containsMinRequiredCharacters],
    ["At least one special character", containsSpecialCharacters],
  ];

  const validatePassword = () => {
    if (payload.password.toLowerCase() !== payload.password)
      setContainsUppercaseLetter(true);
    else setContainsUppercaseLetter(false);

    if (payload.password.toUpperCase() !== payload.password)
      setContainsLowercaseLetter(true);
    else setContainsLowercaseLetter(false);

    if (/\d/.test(payload.password)) setContainsNumber(true);
    else setContainsNumber(false);

    if (payload.password.length >= 8 && payload.password.length <= 30)
      setContainsMinRequiredCharacters(true);
    else setContainsMinRequiredCharacters(false);
    if (SPECIAL_CHARACTER_REGEX.test(payload.password))
      setContainsSpecialCharacter(true);
    else setContainsSpecialCharacter(false);
  };

  const submitFormHandler = useCallback(() => {
    setBtnStates({
      ...btnStates,
      loading: true,
    });
    completeInvite({
      variables: {
        payload: {
          ...payload,
        },
      },
    })
      .then(({ data }) => {
        if (data.completeInvite.success) {
          setBtnStates({
            ...btnStates,
            loading: false,
            success: true,
          });
          setTimeout(() => {
            setAuthenticationData(data.completeInvite, "success");
          }, 1300);
        } else {
          setBtnStates({
            ...btnStates,
            loading: false,
            failed: true,
          });
          data.completeInvite?.failure?.map((item) => {
            return toast.error(`Adding to Space ${item.entity} failed`);
          });
        }
      })
      .catch((e) => {
        toast.error(e?.message || "Something Went Wrong");
        setBtnStates({
          ...btnStates,
          failed: true,
          loading: false,
        });
        setTimeout(() => {
          setBtnStates({
            ...btnStates,
            failed: false,
          });
        }, 2000);
      });
  }, [completeInvite, payload, btnStates]);
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!disabledButton()) {
        if (e.keyCode === 13) {
          submitFormHandler();
        }
      }
    };

    document.addEventListener("keyup", handleKeyPress);
    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, [submitFormHandler, orgId, disabledButton]);
  return (
    <div className="flex flex-col items-center justify-center w-[500px]">
      <div className="text-left w-full flex flex-col gap-[40px]">
        <div className="grid grid-cols-2 gap-x-4">
          <Input
            autoFocus
            label="First Name"
            size="medium"
            name="firstName"
            value={payload.firstName}
            onChange={onChangeHandler}
          />
          <Input
            size="medium"
            label="Last Name"
            name="lastName"
            value={payload.lastName}
            onChange={onChangeHandler}
          />
        </div>
        <Input
          disabled
          size="medium"
          name="email"
          label="Email"
          value={payload.email}
          onChange={onChangeHandler}
        />

        <div>
          <Input
            inputClassName={type === "password" && "font-serif"}
            size="medium"
            label="Password"
            name="password"
            type={type}
            onKeyUp={validatePassword}
            suffix={
              <div
                onClick={passwordTypeHandler}
                className="flex items-center cursor-pointer opacity-30"
              >
                {type === "text" ? IconPasswordHide : IconPasswordShow}
              </div>
            }
            value={payload.password}
            onChange={onChangeHandler}
          />
          <div className="grid grid-cols-2 gap-2 mt-4">
            {passwordRules.map((rule, index) => {
              return <PasswordRule rule={rule} key={index} />;
            })}
          </div>
        </div>
        <Button
          full
          shape="rounded"
          disabled={
            disabledButton() ||
            !(
              containsUppercaseLetter &&
              containsLowercaseLetter &&
              containsNumber &&
              containsMinRequiredCharacters &&
              containsSpecialCharacters
            )
          }
          loading={btnStates.loading}
          success={btnStates.success}
          failure={btnStates.failed}
          label="Continue"
          onClick={submitFormHandler}
        />
      </div>
    </div>
  );
};

export default UserSetupForm;
